import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import Breadcrumb from '../../../components/Breadcrumb/Breadcrumb';
import SimpleSelect from '../../../components/Forms/Select/SimpleSelect';
import { symbols } from '../../../data/symbols';
import { Toaster, toast } from 'sonner';
import { Db_Settings, Db_Bots } from '../../../types/botsAll'; 
import BtnPrimary from '../../../components/Buttons/BtnPrimary'
import { IoIosSave } from "react-icons/io";
import { ApiResponse } from '../../../types/api';
import api from '../../../utils/api';
import Loader from '../../../common/Loader';

const optionsLeverages = [
  { value: "1", label: "1" },
  { value: "10", label: "10" },
  { value: "20", label: "20" },
  { value: "50", label: "50" },
  { value: "100", label: "100" },
];

const optionsExchanges = [
  { value: "binance", label: "binance" },
  { value: "bingx", label: "bingx" },
];

const optionsMarkets = [
  { value: "Market 1", label: "Exchange 1" },
  { value: "Exchange 2", label: "Exchange 2" },
  { value: "Exchange 3", label: "Exchange 3" },
];

const optionsStragies = [
  { value: "TwoMashka", label: "TwoMashka" },
  { value: "AverageTwoMashka", label: "AverageTwoMashka" },
  { value: "Volatility", label: "Volatility" },
];

const BotSettings: React.FC = () => {
  const { botUuid } = useParams<{ botUuid: string }>();
  const [bot, setBot] = useState<Db_Bots | null>(null);
  const [loading, setLoading] = useState(true);
  const [isSaving, setIsSaving] = useState(false);
  const [isChanged, setIsChanged] = useState(false);

  // Состояния для настроек
  const [orderVolume, setOrderVolume] = useState<number | ''>('');
  const [averageStep, setAverageStep] = useState<number | ''>('');
  const [multiplierAverageStep, setMultiplierAverageStep] = useState<number | ''>('');
  const [maximumRange, setMaximumRange] = useState<number | ''>('');
  const [stopLoseRange, setStopLose] = useState<number | ''>('');
  const [takeProfit, setTakeProfit] = useState<number | ''>('');
  const [switcherHandleMode, setSwitcherHandleMode] = useState(false);
  const [leverage, setLeverage] = useState('');
  const [symbol, setSymbol] = useState('');
  const [strategy, setStrategy] = useState('');
  const [exchange, setExchange] = useState('');
  const [market, setMarket] = useState('');

  // Исходные значения настроек
  const [originalSettings, setOriginalSettings] = useState<Db_Settings | null>(null);

  useEffect(() => {
    const fetchBotSettings = async () => {
      try {
        const response = await api.get<ApiResponse<Db_Bots>>(`/bot-api/bots/${botUuid}`);
        console.log("response: ", response.data.result);
        const result = response.data.result;
        if (result) {
          setBot(result);

          // Инициализация состояний настроек
          setOrderVolume(result.settings?.initVolume || '');
          setAverageStep(result.settings?.stepAveraging || '');
          setMultiplierAverageStep(result.settings?.multiplierStepAveraging || '');
          setMaximumRange(result.settings?.maxUp || '');
          setStopLose(result.settings?.loseProfit || '');
          setTakeProfit(result.settings?.takeProfit || '');
          setSwitcherHandleMode(result.settings?.useHedge || false);
          setLeverage(result.settings?.leverage.toString() || '');
          setExchange(result.api?.exchange_name || '');
          setMarket(result.api?.market || '');
          setSymbol(result.symbol || '')

          // Сохраняем исходные значения настроек
          setOriginalSettings(result.settings || null);
        }
      } catch (error) {
        console.error('Error fetching bot settings:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchBotSettings();
  }, [botUuid]);

  const handleLeverageChange = (value: string) => {
    setLeverage(value);
    setIsChanged(true);
  };

  const handleStrategyChange = (value: string) => {
    setStrategy(value);
    setIsChanged(true);
  };

  const handleSymbolChange = (value: string) => {
    setSymbol(value);
    setIsChanged(true);
  };

  const handleSaveChanges = async () => {
    setIsSaving(true);
    try {
      const settings: Partial<Db_Settings> = {
        initVolume: orderVolume === '' ? undefined : Number(orderVolume),
        stepAveraging: averageStep === '' ? undefined : Number(averageStep),
        multiplierStepAveraging: multiplierAverageStep === '' ? undefined : Number(multiplierAverageStep),
        maxUp: maximumRange === '' ? undefined : Number(maximumRange),
        loseProfit: stopLoseRange === '' ? undefined : Number(stopLoseRange),
        takeProfit: takeProfit === '' ? undefined : Number(takeProfit),
        useHedge: switcherHandleMode,
        leverage: leverage === '' ? undefined : Number(leverage),
      };

      // Добавляем неизмененные поля из исходных настроек
      if (originalSettings) {
        Object.keys(originalSettings).forEach(key => {
          if (!(key in settings)) {
            (settings as any)[key] = (originalSettings as any)[key];
          }
        });
      }

      await api.put(`/bot-api/bots/${botUuid}`, { 
        symbol: symbol,
        settings: settings
       });
      setIsChanged(false); 
      toast.success(`Settings saved successfully!`);
    } catch (error) {
      console.error('Error saving bot settings:', error);
      toast.error(`${error}`);
    } finally {
      setIsSaving(false);
    }
  };

  if (loading) {
    return <Loader />;
  }

  if (!bot) {
    return <div>Bot not found</div>;
  }

  console.log("exchange", exchange)
  return (
    <>
      <Toaster position="bottom-center" richColors />
      <Breadcrumb pageName="Bot settings" />
      <h2 className="text-title-md2 font-semibold text-black dark:text-white mb-4">{bot.name}</h2>
        
      <div className='rounded-lg border border-stroke bg-white py-6 px-7.5 shadow-default dark:border-strokedark dark:bg-boxdark'>
        <h3 className="text-title-md1 font-semibold text-black dark:text-white mb-4">Main settings</h3>
        <div className='grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4 mb-4 items-end'>
          <SimpleSelect
            label='Exchange'
            options={optionsExchanges}
            value={exchange}
            onChange={() => {}} 
            disabled={true}
          />
          <SimpleSelect
            label='Market'
            options={optionsMarkets}
            value={market}
            onChange={() => {}} 
            disabled={true}
          />
          <SimpleSelect
            label='Leverage'
            options={optionsLeverages}
            value={leverage}
            onChange={handleLeverageChange} 
          />
          <SimpleSelect
            label='Strategies'
            options={optionsStragies}
            value={strategy}
            onChange={handleStrategyChange} 
          />
          <SimpleSelect
            label='Symbols'
            options={symbols}
            value={symbol}
            onChange={handleSymbolChange} 
          />
        </div>
      </div>

      <div className='w-full flex justify-end mt-4'>
        <BtnPrimary 
          type='submit'
          onClick={handleSaveChanges}
          disabled={!isChanged || isSaving}
        >
          {isSaving ? 
          <div className='flex gap-3 items-center justify-center'>
            <span className="animate-spin">
              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <mask id="path-1-inside-1_1881_16183" fill="white">
                <path d="M15.328 23.5293C17.8047 22.8144 19.9853 21.321 21.547 19.2701C23.1087 17.2193 23.9686 14.72 23.9992 12.1424C24.0297 9.56481 23.2295 7.04587 21.7169 4.95853C20.2043 2.8712 18.0597 1.32643 15.6007 0.552947C13.1417 -0.220538 10.499 -0.181621 8.0638 0.663935C5.62864 1.50949 3.53049 3.11674 2.07999 5.24771C0.629495 7.37868 -0.096238 9.92009 0.0102418 12.4957C0.116722 15.0713 1.04975 17.5441 2.6712 19.5481L4.96712 17.6904C3.74474 16.1796 3.04133 14.3154 2.96106 12.3737C2.88079 10.432 3.42791 8.51604 4.52142 6.90953C5.61493 5.30301 7.19671 4.09133 9.03255 3.45387C10.8684 2.81642 12.8607 2.78708 14.7145 3.3702C16.5683 3.95332 18.1851 5.1179 19.3254 6.69152C20.4658 8.26514 21.0691 10.1641 21.046 12.1074C21.023 14.0506 20.3748 15.9347 19.1974 17.4809C18.02 19.027 16.3761 20.1528 14.5089 20.6918L15.328 23.5293Z"></path></mask><path d="M15.328 23.5293C17.8047 22.8144 19.9853 21.321 21.547 19.2701C23.1087 17.2193 23.9686 14.72 23.9992 12.1424C24.0297 9.56481 23.2295 7.04587 21.7169 4.95853C20.2043 2.8712 18.0597 1.32643 15.6007 0.552947C13.1417 -0.220538 10.499 -0.181621 8.0638 0.663935C5.62864 1.50949 3.53049 3.11674 2.07999 5.24771C0.629495 7.37868 -0.096238 9.92009 0.0102418 12.4957C0.116722 15.0713 1.04975 17.5441 2.6712 19.5481L4.96712 17.6904C3.74474 16.1796 3.04133 14.3154 2.96106 12.3737C2.88079 10.432 3.42791 8.51604 4.52142 6.90953C5.61493 5.30301 7.19671 4.09133 9.03255 3.45387C10.8684 2.81642 12.8607 2.78708 14.7145 3.3702C16.5683 3.95332 18.1851 5.1179 19.3254 6.69152C20.4658 8.26514 21.0691 10.1641 21.046 12.1074C21.023 14.0506 20.3748 15.9347 19.1974 17.4809C18.02 19.027 16.3761 20.1528 14.5089 20.6918L15.328 23.5293Z" stroke="white" stroke-width="14" mask="url(#path-1-inside-1_1881_16183)"></path>
              </svg>
            </span>
            <p>Loading...</p>
          </div> : <><IoIosSave /> Save changes</>}
        </BtnPrimary>
      </div>
    </>
  );
};

export default BotSettings;