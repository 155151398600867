import React, { useState, useEffect } from 'react';
import Breadcrumb from '../../components/Breadcrumb/Breadcrumb';
import CoverOne from '../../images/cover/cover-01.png';
import { BsShieldFillExclamation, BsFillPersonFill, BsBoxArrowLeft, BsLockFill, BsClockHistory } from 'react-icons/bs';
import ModalCenter from '../../components/Modals/ModalCenter';
import ContentConfirm from '../../components/Modals/ContentConfirm';
import { useNavigate } from 'react-router-dom';
import BtnPrimary from '../../components/Buttons/BtnPrimary';
import ContentChangePass from './ContentChangePass';
import ModalSideRight from '../../components/Modals/ModalSideRight';
import ContentActivityHistory from './ContentActivityHistory';
import api from '../../utils/api';
import { toast } from 'sonner';

interface ChangePassProps {
  onClick: () => void;
}

interface ActivityHistoryProps {
  onClick: () => void;
}

const Authenticator2FA = () => {
  return (
    <div className="flex items-center justify-between rounded-md border border-stroke p-2.5 shadow-1 dark:border-strokedark dark:bg-[#37404F]">
      <div className='flex gap-3 items-center'>
        <BsShieldFillExclamation />
        <span className="font-semibold text-black dark:text-white">2FA</span>
        <span className="text-sm">Google Authenticator</span>
      </div>
      <BtnPrimary>Connect</BtnPrimary>
    </div>
  );
};

const ChangePass: React.FC<ChangePassProps> = ({ onClick }) => {
  return (
    <div className="flex items-center justify-between rounded-md border border-stroke p-2.5 shadow-1 dark:border-strokedark dark:bg-[#37404F]">
      <div className='flex gap-3 items-center'>
        <BsLockFill />
        <span className="font-semibold text-black dark:text-white">Password</span>
      </div>
      <BtnPrimary onClick={onClick}>Change</BtnPrimary>
    </div>
  );
};

const ActivityHistory: React.FC<ActivityHistoryProps> = ({ onClick }) => {
  return (
    <div className="flex items-center justify-between rounded-md border border-stroke p-2.5 shadow-1 dark:border-strokedark dark:bg-[#37404F]">
      <div className='flex gap-3 items-center'>
        <BsClockHistory />
        <span className="font-semibold text-black dark:text-white">Activity history</span>
      </div>
      <BtnPrimary onClick={onClick}>Open</BtnPrimary>
    </div>
  );
};

const Profile = () => {
  const [user, setUser] = useState<any>(null);
  const [isModalLogOut, setIsModalLogOut] = useState(false);
  const [isModalChangePass, setIsModalChangePass] = useState(false);
  const [isModalHistory, setIsModalHistory] = useState(false);
  const navigate = useNavigate();

  const toggleModalLogOut = () => {
    setIsModalLogOut(!isModalLogOut);
  };

  const toggleModalChangePass = () => {
    setIsModalChangePass(!isModalChangePass);
  };

  const toggleModalHistory = () => {
    setIsModalHistory(!isModalHistory);
  };

  const handleLogOut = async () => {
    try {
      await api.post('/api/logout');
      toast.success('Logged out successfully');
    } catch (err) {
      toast.error('Failed to log out');
    } finally {
      localStorage.removeItem('user');
      localStorage.removeItem('accessToken');
      localStorage.removeItem('refreshToken');
      navigate('/');
    }
  };

  useEffect(() => {
    const fetchUserData = async () => {
      const userData = JSON.parse(localStorage.getItem('user') || '{}');
      const userId = userData.id;
      if (userId) {
        try {
          const response = await api.get(`/api/admin/user/${userId}`);
          setUser(response.data.result);
        } catch (error) {
          toast.error('Failed to fetch user data');
        }
      }
    };

    fetchUserData();
  }, []);

  return (
    <>
      <Breadcrumb pageName="Profile" />

      <div className="relative overflow-hidden rounded-lg border border-stroke bg-white shadow-default dark:border-strokedark dark:bg-boxdark">
        <div className="p-4 text-center">

          <div className="flex justify-between items-start gap-3">
            <div className='flex flex-col sm:flex-row gap-4'>
              <div className='flex flex-row sm:flex-col justify-start sm:justify-center items-center gap-2'>
                <span className="h-13 w-13 rounded-full flex items-center justify-center bg-[#5A616B]">
                  <BsFillPersonFill className='w-6 h-6' />
                </span>
                <p className="inline-flex rounded-full bg-primary py-1 px-3 text-sm font-medium text-white">{user?.role}</p>
              </div>
              <div className='flex flex-col items-start'>
                <p><span className='font-medium text-black dark:text-white'>Email: </span>{user?.email}</p>
                <p><span className='font-medium text-black dark:text-white'>Name: </span>{user?.name}</p>
              </div>
            </div>
            <BtnPrimary onClick={toggleModalLogOut} style='red' className='absolute top-4 right-4'>
              <BsBoxArrowLeft /> Log out
            </BtnPrimary>
          </div>

          <div className='grid grid-cols-1 sm:grid-cols-2 gap-2 my-4'>
            <ChangePass onClick={toggleModalChangePass} />

            <ModalCenter
              isOpen={isModalChangePass}
              toggleModal={toggleModalChangePass}
              content={
                <ContentChangePass toggleModal={toggleModalChangePass} />
              }
            />

            {/* <Authenticator2FA /> */}
            <ActivityHistory onClick={toggleModalHistory} />

            <ModalSideRight
              isOpen={isModalHistory}
              toggleModal={toggleModalHistory}
              title="ACTIVITY HISTORY"
              content={<ContentActivityHistory toggleModal={toggleModalHistory} />}
            />
          </div>

          <ModalCenter
            isOpen={isModalLogOut}
            toggleModal={toggleModalLogOut}
            content={
              <ContentConfirm
                toggleModal={toggleModalLogOut}
                text="Are you sure you want to log out?"
                onHandle={handleLogOut} />
            }
          />
        </div>
      </div>
    </>
  );
};

export default Profile;