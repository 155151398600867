export const symbols = [
  { 
    value: "BTC-USDT",
    label: "BTC-USDT",
  }, 
  { 
    value: "ETH-USDT",
    label: "ETH-USDT",
  }, 
  { 
    value: "1000PEPE-USDT",
    label: "1000PEPE-USDT", 
  }, 
  { 
    value: "RATS-USDT",
    label: "RATS-USDT",  
  }, 
  { 
    value: "ACE-USDT",
    label: "ACE-USDT",
  }, 
  { 
    value: "ARB-USDT",
    label: "ARB-USDT", 
  }, 
  { 
    value: "BCH-USDT",
    label: "BCH-USDT",
  }, 
  { 
    value: "CHZ-USDT",
    label: "CHZ-USDT", 
  }, 
  { 
    value: "DYDX-USDT",
    label: "DYDX-USDT", 
  }, 
  { 
    value: "LTC-USDT",
    label: "LTC-USDT", 
  }, 
  { 
    value: "NEAR-USDT",
    label: "NEAR-USDT", 
  }, 
  { 
    value: "SOL-USDT",
    label: "SOL-USDT", 
  }, 
  { 
    value: "TRX-USDT",
    label: "TRX-USDT", 
  },
];