import React, { useEffect } from 'react';
import { BsFillTrashFill, BsFillPencilFill, BsClockHistory, BsPersonFillCheck, BsPersonFillDash } from "react-icons/bs";
import Pagination from '../../components/Pagination/Pagination';
import ModalSideRight from '../../components/Modals/ModalSideRight';
import ModalCenter from '../../components/Modals/ModalCenter';
import { USER } from '../../types/user';
import { Toaster, toast } from 'sonner';
import User from './User';
import ContentConfirm from '../../components/Modals/ContentConfirm';
import ContentActivityHistory from '../Profile/ContentActivityHistory';
import NotFound from '../../common/NotFound';
import { useUsers } from '../../hooks/useUsers';
import { UserRoles } from '../../types/user';

interface UsersProps {
  users: USER[];
  updateUsers: () => void;
}

const TableUsers: React.FC<UsersProps> = ({ users, updateUsers }) => {
  const {
    selectedUser,
    isModalEditOpen,
    isModalDeleteOpen,
    isModalHistoryOpen,
    toggleModalEdit,
    toggleModalDelete,
    toggleModalHistory,
    handleEditUser,
    handleDeleteUser,
    handleDeleteConfirm,
    isAdmin,
  } = useUsers();

  const handleEditSave = (result: boolean) => {
    if (result) {
      toast.success('User edited successfully');
    } else {
      toast.error('Failed to edit user');
    }
  };

  useEffect(() => {
    // Обновляем таблицу только при изменении users
  }, [users]);

  return (
    <>
      <Toaster position="bottom-center" richColors />
      <div className="overflow-hidden rounded-[10px]">
        <div className="max-w-full overflow-x-auto">
          <div className="min-w-[1170px]">

            <div className="grid grid-cols-12 gap-4 bg-[#F9FAFB] px-5 py-4 dark:bg-meta-4 lg:px-7.5 2xl:px-11">
              <div className="col-span-1">
                <h5 className="font-medium text-[#637381] dark:text-bodydark">#</h5>
              </div>
              <div className="col-span-2">
                <h5 className="font-medium text-[#637381] dark:text-bodydark">NAME</h5>
              </div>
              <div className="col-span-2">
                <h5 className="font-medium text-[#637381] dark:text-bodydark">PERMISSION</h5>
              </div>
              <div className="col-span-3">
                <h5 className="font-medium text-[#637381] dark:text-bodydark">EMAIL</h5>
              </div>
              <div className="col-span-2">
                <h5 className="font-medium text-[#637381] dark:text-bodydark">COMMENT</h5>
              </div>
              <div className="col-span-1">
                <h5 className="font-medium text-[#637381] dark:text-bodydark">ACTIONS</h5>
              </div>
            </div>

            <div className="bg-white dark:bg-boxdark">

              {users.length === 0 && Array.isArray(users) ? (
                <NotFound />
              ) : (users.map((user, key) => (
                <div className="grid grid-cols-12 gap-4 border-t border-[#EEEEEE] dark:border-strokedark px-5 py-4 lg:px-7.5 2xl:px-11" key={key}>
                  <div className="col-span-1 flex items-center">
                    <p className="text-[#637381] dark:text-bodydark">{key + 1}</p>
                  </div>
                  <div className="col-span-2 flex items-center gap-2">
                    {user.role === UserRoles.Admin ? <BsPersonFillCheck className='text-xl text-green-700' /> : <BsPersonFillDash className='text-xl text-red-700' />}
                    <p className="text-[#637381] dark:text-bodydark">{user.name}</p>
                  </div>
                  <div className="col-span-2 flex items-center">
                    {user?.role === UserRoles.Admin && <p className='inline-flex rounded-full bg-primary py-1 px-3 text-sm font-medium text-white'>{user?.role}</p>}
                    {user?.role === UserRoles.Moderator && <p className="inline-flex rounded-full bg-[#637381] py-1 px-3 text-sm font-medium text-white">{user.role}</p>}
                    {user?.role === UserRoles.User && <p className="inline-flex rounded-full bg-[#EFEFEF] py-1 px-3 text-sm font-medium text-[#212B36]">{user.role}</p>}
                  </div>
                  <div className="col-span-3 flex items-center">
                    <p className="text-[#637381] dark:text-bodydark">{user.email}</p>
                  </div>
                  <div className="col-span-2 flex items-center">
                    <p className="text-[#637381] dark:text-bodydark">{user.description}</p>
                  </div>
                  <div className="col-span-1 flex items-center justify-center gap-4">
                    {isAdmin() && (
                      <>
                        <BsFillPencilFill
                          className="cursor-pointer duration-300 ease-in-out hover:text-primary"
                          onClick={() => handleEditUser(user)} />

                        <ModalSideRight
                          isOpen={isModalEditOpen && selectedUser?.id === user.id}
                          toggleModal={toggleModalEdit}
                          title="EDIT USER"
                          content={
                            <User
                              user={user}
                              action="edit"
                              toggleModal={toggleModalEdit}
                              onSave={handleEditSave}
                              updateUsers={updateUsers}
                               />
                          }
                        />

                        <BsFillTrashFill
                          className="cursor-pointer duration-300 ease-in-out hover:text-primary"
                          onClick={() => handleDeleteUser(user)}
                        />

                        <ModalCenter
                          isOpen={isModalDeleteOpen && selectedUser?.id === user.id}
                          toggleModal={toggleModalDelete}
                          content={
                            selectedUser && (
                              <ContentConfirm
                                toggleModal={toggleModalDelete}
                                text="Are you sure you want to delete this user?"
                                onHandle={handleDeleteConfirm}
                                updateItems={updateUsers}
                              />
                            )
                          }
                        />
                      </>
                    )}

                    {/* {isAdmin() && (
                      <BsClockHistory
                        className='cursor-pointer duration-300 ease-in-out hover:text-primary'
                        onClick={() => toggleModalHistory()} />
                    )}

                    <ModalSideRight
                      isOpen={isModalHistoryOpen && selectedUser?.id === user.id}
                      toggleModal={toggleModalHistory}
                      title="ACTIVITY HISTORY"
                      content={
                        <ContentActivityHistory toggleModal={toggleModalHistory} />
                      }
                    /> */}

                  </div>
                </div>
              )))}

            </div>

          </div>
        </div>
      </div>

    </>
  );
};

export default TableUsers;