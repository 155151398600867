import React from 'react';
import { useParams } from 'react-router-dom';
import Breadcrumb from '../../components/Breadcrumb/Breadcrumb';
import TableOrders from './TableOrder';
import AddOrder from './AddOrder';
import Chart from './Chart';
import { Toaster, toast } from 'sonner';
import { useManualTrading } from '../../hooks/useManualTrading';
import { MdAccountBalanceWallet } from "react-icons/md";
import TablePositions from './TablePositions';


const ManualTrading: React.FC = () => {
  const { keyId } = useParams<{ keyId: string }>();
  const { accountInfo } = useManualTrading(keyId);

  return (
    <>
      <Breadcrumb pageName="Manual trading" />
      <Toaster position="bottom-center" richColors />

      <div className='flex gap-2 items-center mb-4'>
        <MdAccountBalanceWallet className='text-xl' />
        <p>Avaliable: {accountInfo?.balance.balance}$</p>
      </div>
      
      <div className='grid grid-cols-1 md:grid-cols-3 gap-4'>
        <div className='h-[450px] md:h-full md:col-span-2'>
          <Chart />
        </div>
        <div className='md:col-span-1'>
          <AddOrder />
        </div>
      </div>

      <h2 className="text-title-md2 font-semibold text-black dark:text-white mt-4 mb-2">Positions {`(${accountInfo?.positions.length})`}</h2>

      <div className="grid grid-cols-12 gap-4 md:gap-6 2xl:gap-7.5 mt-4">
        <div className="col-span-12">
          <TablePositions accountInfo={accountInfo} />
        </div>
      </div>

      <h2 className="text-title-md2 font-semibold text-black dark:text-white mt-4 mb-2">Orders {`(${accountInfo?.orders.length})`}</h2>

      <div className="grid grid-cols-12 gap-4 md:gap-6 2xl:gap-7.5 mt-4">
        <div className="col-span-12">
          <TableOrders accountInfo={accountInfo} />
        </div>
      </div>
    </>
  );
};

export default ManualTrading;