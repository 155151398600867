import React, { useState, useEffect } from 'react';
import { Toaster, toast } from 'sonner';
import { BsRobot, BsTrash, BsPencil, BsPlusCircleFill, BsXCircleFill } from "react-icons/bs";
import ModalCenter from '../../components/Modals/ModalCenter';
import InputField from '../../components/Forms/Inputs/InputField';
import { KEY } from '../../types/key';
import BtnPrimary from '../../components/Buttons/BtnPrimary';
import ContentDelete from '../../components/Modals/ContentConfirm';
import api from '../../utils/api'
import SimpleSelect from '../../components/Forms/Select/SimpleSelect';
import { getCurrentUser } from "../../utils/getCurrentUser"

enum KeyActions {
  Add = "add",
  Edit = "edit",
}

const exchangers = [
  {
    value: "BingX",
    label: "BingX",
  },
  // {
  //   value: "Binance",
  //   label: "Binance",
  // },
];

const markets = [
  {
    value: "Futures",
    label: "Futures",
  },
]

interface KeyProps {
  toggleModal: () => void;
  keyItem?: KEY;
  updateKeys?: () => void;
  onSave?: (result: boolean) => void;
  action: string;
}

const Key: React.FC<KeyProps> = ({ toggleModal, keyItem, updateKeys, onSave, action }) => {
  const [name, setName] = useState(keyItem?.name || '');
  const [exchanger, setExchanger] = useState('BingX')
  const [market, setMarket] = useState('Futures')
  const [subAccId, setSubAccId] = useState(keyItem?.subAccountId || '');
  const [publicKey, setSetPublicKey] = useState(keyItem?.public_key || '');
  const [secretKey, setSecretKey] = useState(keyItem?.private_key || '');

  const handleNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setName(e.target.value);
  };

  const handleSubAccIdhange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSubAccId(e.target.value);
  };

  const handlePublicKeyChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSetPublicKey(e.target.value);
  };

  const handleSecretKeyChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSecretKey(e.target.value);
  };

  const handleExchangerChange = (value: string) => {
    setExchanger(value);
  };

  const handleMarketChange = (value: string) => {
    setMarket(value);
  };

  const handleAddKey = async (e: React.FormEvent) => {
    e.preventDefault();

    if (name === '' || exchanger === '' || market === '' || publicKey === '' || secretKey === '') {
      toast.error('Please fill all fields form');
      return;
    }

    // const keyData = {
    //   exchangers,
    //   name,
    //   market,
    //   subAccId,
    //   publicKey,
    //   secretKey
    // };

    const user = getCurrentUser()
    console.log("user: ", user)

    const keyData_time = {
      // user_id: user?.id,
      name: name,
      exchange_name: exchanger,
      public_key: publicKey,
      private_key: secretKey,
    }

    try {
      const response = await api.post(`/api/api_keys`, keyData_time);
      updateKeys?.()
      toggleModal();
      onSave?.(true);
    } catch (error) {
      console.log("Error :", error)
      onSave?.(false);
    }
  };

  const handleEditKey = async (e: React.FormEvent) => {
    e.preventDefault();

    // if (name === '' || subAccId === '') {
    //   toast.error('Please fill all fields form');
    //   return;
    // }

    // const keyData = {
    //   name,
    //   subAccountId: subAccId,
    // };

    const keyData = {
      name: name,
      memo: "Updated memo",
    }

    try {
      const response = await api.put(`/api/api_keys/${keyItem?.id}`, keyData);
      console.log("Response :", response.data)
      updateKeys?.()
      toggleModal();
      onSave?.(true);
    } catch (error) {
      console.log("Error :", error)
      onSave?.(false);
    }
  }


  return (
    <>
      <form onSubmit={action === KeyActions.Add ? handleAddKey : handleEditKey} className="flex flex-col h-full">
        <div className="flex-grow mb-4">
          {action === KeyActions.Add &&
            <div>
              <SimpleSelect
                label="Exchangers"
                options={exchangers}
                value={exchanger}
                onChange={handleExchangerChange}
                className="w-full mb-4"
              />
              <SimpleSelect
                label="Market"
                options={markets}
                value={market}
                onChange={handleMarketChange}
                className="w-full mb-4"
              />
            </div>
          }

          <InputField
            label="Name"
            value={name}
            onChange={handleNameChange}
            placeholder="Name task"
            className="mb-4"
          />

          <InputField
            label="Sub Account ID"
            value={subAccId}
            onChange={handleSubAccIdhange}
            placeholder="Sub Account ID"
            className="mb-4"
          />

          <InputField
            label="Public key"
            value={publicKey}
            onChange={handlePublicKeyChange}
            placeholder="Public key"
            className="mb-4"
            disabled={action === KeyActions.Edit}
          />

          {action === KeyActions.Add &&
            <InputField
              label="Secret key"
              value={secretKey}
              onChange={handleSecretKeyChange}
              placeholder="Secret key"
              className="mb-4"
            />}

        </div>

        <div className='flex items-center justify-between gap-4 mt-auto'>
          <BtnPrimary
            // disabled={action === KeyActions.Add ? 
            //   name === '' || exchanger === '' || market === '' || subAccId === '' || publicKey === '' || secretKey === '' 
            //   : name === '' || subAccId === ''}  
            className='w-full'
            type='submit'>
            <BsPlusCircleFill />  {action === KeyActions.Add ? 'Add ' : 'Edit '}key
          </BtnPrimary>
          <BtnPrimary
            className='w-full'
            style="outline"
            onClick={() => {
              toggleModal();
            }}>
            <BsXCircleFill /> Close
          </BtnPrimary>
        </div>
      </form>
    </>
  );
};

export default Key;