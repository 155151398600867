// Chart.tsx
import React from 'react';
import TradingViewWidget from './TradingViewWidget';

const Chart: React.FC = () => {
  
  // const colorTheme = localStorage.getItem('color-theme')

  let backgroundColor = 'rgba(36, 48, 63, 1)'; 
  // if(colorTheme == "dark") {
  //   backgroundColor = 'rgba(36, 48, 63, 1)';
  // } else{
  //   backgroundColor = 'rgba(255, 255, 255, 1)';
  // } 

  return (
    <div className={`rounded-lg mb-4 border border-stroke bg-white dark:bg-boxdark dark:border-strokedark px-3 py-2.5 shadow-default h-full`}>
      <TradingViewWidget backgroundColor={backgroundColor} />
    </div>
  );
};

export default Chart;