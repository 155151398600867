import { BsFillTrashFill, BsFillPencilFill, BsFillSendFill, BsRepeat } from "react-icons/bs";
import { BOT } from '../../types/bot';
import React, { useState } from 'react';
import ModalCenter from '../../components/Modals/ModalCenter';
import ContentModalClose from '../Bots/ContentModalClose';
import BtnPrimary from "../../components/Buttons/BtnPrimary";
import { BsFillLockFill } from "react-icons/bs";
import { AccountInfo, Position, Order } from "../../types/manualTrade";
import NotFound from "../../common/NotFound";
import { useManualTrading } from '../../hooks/useManualTrading';
import { useParams } from 'react-router-dom';
import { formatCurrency } from "../../utils/formatCurrency";

interface TableProps {
  accountInfo: AccountInfo | null;
}

const TableOrders: React.FC<TableProps> = ({ accountInfo }) => {
  const [isModalClose, setIsModalClose] = useState(false);
  const [selectedBot, setSelectedBot] = useState<BOT | null>(null);

  const { keyId } = useParams<{ keyId: string }>();
  const {
    handleCloseOrderSubmit,
  } = useManualTrading(keyId);

  const toggleModalClose = (bot: BOT) => {
    setSelectedBot(bot);
    setIsModalClose(!isModalClose);
  };

  const getProfitClass = (profit: number): string => {
    if (profit === 0) {
      return 'text-gray-500';
    } else if (profit < 0) {
      return 'text-red-500';
    } else {
      return 'text-green-500';
    }
  };

  return (
    <div className="overflow-hidden rounded-[10px]">
      <div className="max-w-full overflow-x-auto">
        <div className="min-w-[1170px]">

          <div className="grid grid-cols-12 gap-4 bg-[#F9FAFB] px-5 py-4 dark:bg-meta-4 lg:px-7.5 2xl:px-11">
            <div className="col-span-2">
              <h5 className="font-medium text-[#637381] text-center">POSITION</h5>
            </div>
            <div className="col-span-1">
              <h5 className="font-medium text-[#637381] text-center">AMOUNT</h5>
            </div>
            <div className="col-span-2">
              <h5 className="font-medium text-[#637381] text-center">TYPE</h5>
            </div>
            <div className="col-span-3">
              <h5 className="font-medium text-[#637381] text-center">SIDE</h5>
            </div>
            <div className="col-span-2">
              <h5 className="font-medium text-[#637381] text-center">PRICE</h5>
            </div>
            <div className="col-span-1">
              <h5 className="font-medium text-[#637381] dark:text-bodydark text-center">ACTIONS</h5>
            </div>
          </div>

          <div className="bg-white dark:bg-boxdark">

            {
              !accountInfo || !accountInfo.orders || accountInfo.orders.length === 0 ? (
                <NotFound />
              ) : (
                accountInfo?.orders.map((order, key) => (
                  <div className="grid grid-cols-12 gap-4 border-t border-[#EEEEEE] px-5 py-4 dark:border-strokedark lg:px-7.5 2xl:px-11" key={key}>
                    <div className="col-span-2 flex items-center justify-center">
                      <p className="inline-flex rounded-full border border-[#3CA745] py-1 px-3 text-sm font-medium text-[#3CA745] hover:opacity-80 uppercase">{order.positionSide}</p>
                    </div>
                    <div className="col-span-1 items-center">
                      <p className="text-[#637381] text-center">{order.origQty}</p>
                    </div>
                    <div className="col-span-2 items-center">
                      <p className="text-center text-[#637381]">{order.type}</p>
                    </div>
                    <div className="col-span-3 items-center">
                      <p className={`text-center ${order.side === 'BUY' ? 'border-[#3CA745] text-[#3CA745]' : 'border-[#FF5733] text-[#FF5733]'}`}>{order.side}</p>
                    </div>
                    <div className="col-span-2 items-center">
                    <p className={`text-[#637381] text-center`}>
                      {order.type === 'LIMIT' ? formatCurrency(order.price) : formatCurrency(order.stopPrice)}
                    </p>
                    </div>
                    <div className="col-span-1 flex items-center justify-center">
                      <BtnPrimary style="red" onClick={() => handleCloseOrderSubmit(order.clientOrderID)}><BsFillLockFill /> Cancel</BtnPrimary>
                    </div>
                  </div>
                ))
              )
            }

          </div>

          {selectedBot && (
            <ModalCenter
              isOpen={isModalClose}
              toggleModal={() => toggleModalClose(selectedBot)}
              content={<ContentModalClose />}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default TableOrders;