import React, { useState, useEffect, useRef } from 'react';
import { BsExclamationCircle } from 'react-icons/bs';

interface Option {
  value: string;
  label: string;
}

interface MultiSelectProps {
  label?: string;
  options: Option[];
  defaultValue?: string[];
  onChange?: (selectedValues: string[]) => void;
  disabled?: boolean; 
  className?: string;
}

const MultiSelect: React.FC<MultiSelectProps> = ({ label, options, defaultValue = [], onChange, disabled = false, className }) => {
  const [selected, setSelected] = useState<string[]>(defaultValue);
  const [show, setShow] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const dropdownRef = useRef<any>(null);
  const trigger = useRef<any>(null);

  const open = () => {
    if (!disabled) {
      setShow(true);
    }
  };

  const isOpen = () => {
    return show === true;
  };

  const select = (value: string) => {
    const newSelected = [value]; 
    setSelected(newSelected);
    if (onChange) {
      onChange(newSelected);
    }
    setShow(false); 
  };

  const remove = (value: string) => {
    if (disabled) return; 

    const newSelected = selected.filter(item => item !== value);
    setSelected(newSelected);
    if (onChange) {
      onChange(newSelected);
    }
  };

  const clearSearch = () => {
    setSearchQuery('');
  };

  useEffect(() => {
    const clickHandler = ({ target }: MouseEvent) => {
      if (!dropdownRef.current) return;
      if (
        !show ||
        dropdownRef.current.contains(target) ||
        trigger.current.contains(target)
      )
        return;
      setShow(false);
    };
    document.addEventListener('click', clickHandler);
    return () => document.removeEventListener('click', clickHandler);
  });

  const filteredOptions = options.filter(option =>
    option.label.toLowerCase().includes(searchQuery.toLowerCase())
  );

  return (
    <div className={`relative z-50 ${className}`}>
      {label && (
        <label className="mb-2.5 block font-medium text-[#637381] dark:text-white">
          {label}
        </label>
      )}
      <div className="flex flex-col items-center">
        <div className="relative z-20 inline-block w-full">
          <div className="relative flex flex-col items-center">
            <div ref={trigger} onClick={open} className={`w-full cursor-pointer ${disabled ? 'cursor-not-allowed' : ''}`}>
              <div className={`mb-2 flex rounded border border-stroke py-2 pl-3 pr-3 outline-none transition focus:border-primary active:border-primary dark:border-form-strokedark dark:bg-form-input ${disabled ? 'bg-gray-200 text-gray-400' : ''}`}>
                <div className="flex flex-auto flex-wrap gap-3">
                  {selected.map((value) => (
                    <div
                      key={value}
                      className="my-1.5 flex items-center justify-center rounded border-[.5px] border-stroke bg-gray px-2.5 py-1.5 text-sm font-medium dark:border-strokedark dark:bg-white/30"
                    >
                      <div className="max-w-full flex-initial text-black dark:text-white">
                        {options.find(option => option.value === value)?.label}
                      </div>
                      {!disabled && (
                        <div className="flex flex-auto flex-row-reverse">
                          <div
                            onClick={() => remove(value)}
                            className="cursor-pointer pl-2 hover:text-danger text-white"
                          >
                            <svg
                              className="fill-current"
                              role="button"
                              width="12"
                              height="12"
                              viewBox="0 0 12 12"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                fillRule="evenodd"
                                clipRule="evenodd"
                                d="M9.35355 3.35355C9.54882 3.15829 9.54882 2.84171 9.35355 2.64645C9.15829 2.45118 8.84171 2.45118 8.64645 2.64645L6 5.29289L3.35355 2.64645C3.15829 2.45118 2.84171 2.45118 2.64645 2.64645C2.45118 2.84171 2.45118 3.15829 2.64645 3.35355L5.29289 6L2.64645 8.64645C2.45118 8.84171 2.45118 9.15829 2.64645 9.35355C2.84171 9.54882 3.15829 9.54882 3.35355 9.35355L6 6.70711L8.64645 9.35355C8.84171 9.54882 9.15829 9.54882 9.35355 9.35355C9.54882 9.15829 9.54882 8.84171 9.35355 8.64645L6.70711 6L9.35355 3.35355Z"
                                fill="currentColor"
                              ></path>
                            </svg>
                          </div>
                        </div>
                      )}
                    </div>
                  ))}
                  {selected.length === 0 && (
                    <div className="flex flex-1 items-center">
                      <span className="text-gray-400 whitespace-nowrap">Select an option</span>
                    </div>
                  )}
                </div>
                <div className="flex w-8 items-center py-1 pl-1 pr-1">
                  <button
                    type="button"
                    onClick={open}
                    className={`h-6 w-6 cursor-pointer outline-none focus:outline-none ${disabled ? 'cursor-not-allowed' : ''}`}
                  >
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g opacity="0.8">
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M5.29289 8.29289C5.68342 7.90237 6.31658 7.90237 6.70711 8.29289L12 13.5858L17.2929 8.29289C17.6834 7.90237 18.3166 7.90237 18.7071 8.29289C19.0976 8.68342 19.0976 9.31658 18.7071 9.70711L12.7071 15.7071C12.3166 16.0976 11.6834 16.0976 11.2929 15.7071L5.29289 9.70711C4.90237 9.31658 4.90237 8.68342 5.29289 8.29289Z"
                          fill="#637381"
                        ></path>
                      </g>
                    </svg>
                  </button>
                </div>
              </div>
            </div>
            <div className="w-full px-4">
              <div
                className={`max-h-60 overflow-y-auto absolute top-full left-0 z-40 w-full rounded bg-white shadow dark:bg-form-input transition-all duration-300 ease-in-out ${
                  isOpen() ? 'opacity-100 visible' : 'opacity-0 invisible'
                }`}
                ref={dropdownRef}
                onFocus={() => setShow(true)}
                onBlur={() => setShow(false)}
              >
                <div className="relative w-full p-2">
                  <span className="absolute left-5 top-1/2 -translate-y-1/2">
                    <svg
                      className="fill-body hover:fill-primary dark:fill-bodydark dark:hover:fill-primary"
                      width="20"
                      height="20"
                      viewBox="0 0 20 20"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M9.16666 3.33332C5.945 3.33332 3.33332 5.945 3.33332 9.16666C3.33332 12.3883 5.945 15 9.16666 15C12.3883 15 15 12.3883 15 9.16666C15 5.945 12.3883 3.33332 9.16666 3.33332ZM1.66666 9.16666C1.66666 5.02452 5.02452 1.66666 9.16666 1.66666C13.3088 1.66666 16.6667 5.02452 16.6667 9.16666C16.6667 13.3088 13.3088 16.6667 9.16666 16.6667C5.02452 16.6667 1.66666 13.3088 1.66666 9.16666Z"
                        fill=""
                      />
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M13.2857 13.2857C13.6112 12.9603 14.1388 12.9603 14.4642 13.2857L18.0892 16.9107C18.4147 17.2362 18.4147 17.7638 18.0892 18.0892C17.7638 18.4147 17.2362 18.4147 16.9107 18.0892L13.2857 14.4642C12.9603 14.1388 12.9603 13.6112 13.2857 13.2857Z"
                        fill=""
                      />
                    </svg>
                  </span>

                  <input
                    autoComplete='off'
                    type="text"
                    placeholder="Type to search"
                    value={searchQuery}
                    onChange={(e) => setSearchQuery(e.target.value)}
                    className="w-full rounded border-[1.5px] border-stroke bg-transparent py-3 px-5 text-black outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:text-white dark:focus:border-primary pl-10 pr-7"
                    disabled={disabled}
                  />

                  {searchQuery && (
                    <span className="absolute right-5 top-1/2 -translate-y-1/2 cursor-pointer" onClick={clearSearch}>
                      <svg
                        className="fill-current"
                        role="button"
                        width="12"
                        height="12"
                        viewBox="0 0 12 12"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M9.35355 3.35355C9.54882 3.15829 9.54882 2.84171 9.35355 2.64645C9.15829 2.45118 8.84171 2.45118 8.64645 2.64645L6 5.29289L3.35355 2.64645C3.15829 2.45118 2.84171 2.45118 2.64645 2.64645C2.45118 2.84171 2.45118 3.15829 2.64645 3.35355L5.29289 6L2.64645 8.64645C2.45118 8.84171 2.45118 9.15829 2.64645 9.35355C2.84171 9.54882 3.15829 9.54882 3.35355 9.35355L6 6.70711L8.64645 9.35355C8.84171 9.54882 9.15829 9.54882 9.35355 9.35355C9.54882 9.15829 9.54882 8.84171 9.35355 8.64645L6.70711 6L9.35355 3.35355Z"
                          fill="currentColor"
                        ></path>
                      </svg>
                    </span>
                  )}
                </div>
                <div className="flex w-full flex-col">
                  {filteredOptions.length > 0 ? (
                    filteredOptions.map((option) => (
                      <div key={option.value}>
                        <div
                          className="w-full cursor-pointer rounded-t border-b border-stroke hover:bg-primary/5 dark:border-form-strokedark"
                          onClick={() => select(option.value)}
                        >
                          <div
                            className={`relative flex w-full items-center border-l-2 border-transparent p-2 pl-2 ${
                              selected.includes(option.value) ? 'border-primary' : ''
                            }`}
                          >
                            <div className="flex w-full items-center">
                              <div className="mx-2 leading-6">
                                {option.label}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))
                  ) : (
                    <div className="flex flex-col items-center justify-center p-5">
                      <BsExclamationCircle className="text-4xl text-gray-400 mb-2" />
                      <p className="text-gray-600">No symbols found</p>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div> 
  );
};

export default MultiSelect;


