import { useState, useEffect } from 'react';
import { symbols } from '../data/symbols';
import api from '../utils/api';
import axios from 'axios';
import { ApiResponse } from '../types/api';
import { AccountInfo } from '../types/manualTrade';

export const useManualTrading = (keyId: string | undefined) => {
  const [symbol, setSymbol] = useState(symbols[0].value);
  const [type, setType] = useState('Market');
  const [leverage, setLeverage] = useState<number | ''>(50);
  const [amount, setAmount] = useState<number | ''>(0);
  const [takeProfit, setTakeProfit] = useState<number | ''>(0);
  const [stopLoss, setStopLoss] = useState<number | ''>(0);
  const [price, setPrice] = useState<number | ''>(0);
  const [position, setPosition] = useState('');
  const [accountInfo, setAccountInfo] = useState<AccountInfo | null>(null);
  const [isInitialFetch, setIsInitialFetch] = useState(true);

  const handleLeverageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setLeverage(value === '' ? '' : parseInt(value, 10));
  };

  const handleSelectChange = (value: string[]) => {
    setSymbol(value[0]);
  };

  const handleTypeChange = (value: string) => {
    setType(value);
  };

  const handlePositionChange = (value: string) => {
    setPosition(value);
  };

  const handleAmountChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setAmount(value === '' ? '' : parseFloat(value));
  };

  const handleTakeProfitChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setTakeProfit(value === '' ? '' : parseFloat(value));
  };

  const handleStopLossChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setStopLoss(value === '' ? '' : parseFloat(value));
  };

  const handlePriceChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setPrice(value === '' ? '' : parseFloat(value));
  };

  const handleCloseOrderSubmit = async (orderId: string) => {
    let data = { action: "cancel", symbol, orderId }
    try {
      const response = await api.post(`/trade-api/order/${keyId}`, data);
      if (response.data.code === 200 && response.data.result) {
        console.log('success');
        return true;
      } else {
        console.log('Failed request');
        return false;
      }
    } catch (error) {
      console.log(`error catch ${error}`);
      return false;
    }
  }

  const handleSubmit = async (position: string) => {
    handlePositionChange(position);

    let orderData = {};
    switch (type) {
      case 'Market':
        orderData = {
          symbol: symbol,
          leverage: leverage,
          action: "open",
          keyId: keyId,
          position: position,
          amount: amount,
          takeProfit: takeProfit,
          stopLoss: stopLoss,
          orderType: type,
        };
        break;
      case 'Limit':
        orderData = {
          symbol: symbol,
          leverage: leverage,
          action: "open",
          keyId: keyId,
          position: position,
          amount: amount,
          price: price,
          takeProfit: takeProfit,
          stopLoss: stopLoss,
          orderType: type,
        };
        break;
      case 'Take Profit':
        orderData = {
          symbol: symbol,
          leverage: leverage,
          action: "open",
          keyId: keyId,
          position: position,
          amount: amount,
          takeProfit: takeProfit,
          orderType: type,
        };
        break;
      case 'Stop Loss':
        orderData = {
          symbol: symbol,
          leverage: leverage,
          action: "open",
          keyId: keyId,
          position: position,
          amount: amount,
          stopLoss: stopLoss,
          orderType: type,
        };
        break;
      default:
        break;
    }

    console.log('Order Data:', orderData);

    try {
      const response = await api.post(`/trade-api/order/${keyId}`, orderData);
      if (response.data.code === 200 && response.data.result) {
        console.log('success');
        return true;
      } else {
        console.log('Failed request');
        return false;
      }
    } catch (error) {
      console.log(`error catch ${error}`);
      return false;
    }
  };

  useEffect(() => {
    console.log("useEffect")
    const fetchAccountInfo = async () => {
      if (!isInitialFetch) return;

      let data = { symbol }
      try {
        const response = await api.post(`/trade-api/account_info/${keyId}`, data);
        console.log(response.data.result)
        setAccountInfo(response.data.result);
        setIsInitialFetch(false);
      } catch (error) {
        console.error('Error fetching account info:', error);
      }
    };

    fetchAccountInfo();

    const intervalId = setInterval(fetchAccountInfo, 15000);

    return () => clearInterval(intervalId);
  }, [keyId, isInitialFetch]);

  return {
    symbol,
    type,
    leverage,
    amount,
    takeProfit,
    stopLoss,
    position,
    price,
    accountInfo,
    handleLeverageChange,
    handleSelectChange,
    handleTypeChange,
    handleAmountChange,
    handleTakeProfitChange,
    handleStopLossChange,
    handlePriceChange,
    handleSubmit,
    handleCloseOrderSubmit,
  };
};