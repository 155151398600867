import { useState, useEffect } from 'react';
import { validateEmail, validatePassword } from '../utils/validations';
import { toast } from 'sonner';

interface FormValues {
  email: string;
  password: string;
}

interface FormErrors {
  email?: string;
  password?: string;
}

export const useForm = (initialState: FormValues) => {
  const [values, setValues] = useState<FormValues>(initialState);
  const [errors, setErrors] = useState<FormErrors>({});
  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    if (isSubmitting) {
      const noErrors = Object.keys(errors).length === 0;
      if (noErrors) {
        setIsSubmitting(false);
      } else {
        setIsSubmitting(false);
        toast.error('Please correct the errors in the form');
      }
    }
  }, [errors, isSubmitting]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setValues({
      ...values,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = (callback: () => void) => (e: React.FormEvent) => {
    e.preventDefault();
    setErrors(validate(values));
    setIsSubmitting(true);
    callback();
  };

  return {
    values,
    errors,
    handleChange,
    handleSubmit,
  };
};

const validate = (values: FormValues) => {
  const errors: FormErrors = {};
  if (!validateEmail(values.email)) {
    errors.email = 'Invalid email address';
  }
  if (!validatePassword(values.password)) {
    errors.password = 'Password must be at least 6 characters long';
  }
  return errors;
};