import { BsFillTrashFill, BsFillPencilFill } from "react-icons/bs";
import React, { useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router-dom'
import ModalCenter from '../../components/Modals/ModalCenter';
import { BsExclamationCircle } from 'react-icons/bs'
import ModalSideRight from "../../components/Modals/ModalSideRight";
import { KEY, OrderHistory } from "../../types/key";
import { Toaster, toast } from 'sonner';
import Key from "./Key";
import Pagination from '../../components/Pagination/Pagination';
import ContentConfirm from "../../components/Modals/ContentConfirm";
import GrabPoint from '../../components/GrabPoint'
import { PiTrademarkFill } from "react-icons/pi";
import { BsClockHistory } from "react-icons/bs";
import { useApiKeys } from '../../hooks/useApiKeys';
import NotFound from "../../common/NotFound";
import { formatDate } from "../../utils/formatDate";
import { formatCurrency } from "../../utils/formatCurrency";
import { formatAmount } from '../../utils/formatAmount'

interface HistoryOrdersModalProps {
  isOpen: boolean;
  toggleModal: () => void;
  keyItem: KEY;
  orderHistory: OrderHistory[];
  orderHistoryPage: number;
  orderHistoryTotalCount: number;
  handleOrderHistoryPageChange: (selectedItem: { selected: number }) => void;
}

const HistoryOrdersModal: React.FC<HistoryOrdersModalProps> = ({
  isOpen,
  toggleModal,
  keyItem,
  orderHistory,
  orderHistoryPage,
  orderHistoryTotalCount,
  handleOrderHistoryPageChange,
}) => {
  const orderHistoryPerPage = 10; 
  const pageCount = Math.ceil((orderHistoryTotalCount || 0) / orderHistoryPerPage);

  const getProfitClass = (profit: number): string => {
    if (profit === 0) {
      return 'text-gray-500';
    } else if (profit < 0) {
      return 'text-red-500';
    } else {
      return 'text-green-500';
    }
  };

  return (
    <ModalSideRight
      isOpen={isOpen}
      toggleModal={toggleModal}
      title="History Orders"
      content={
        <div className="flex-grow mb-4 flex flex-col gap-2">
          {orderHistory.map((order) => (
            <div key={order.id} className="flex flex-col gap-2 rounded-md border border-stroke p-2.5 shadow-1 dark:border-strokedark dark:bg-[#37404F]">
              
              <div className="flex justify-between items-center">
                <div><p className="text-sm font-semibold text-black dark:text-white"> {`${formatAmount(order.origQty)} ${order.symbol} (${formatCurrency(Number(order.origQty) * Number(order.avgPrice))})`}</p></div>
                <div>
                  <p className={getProfitClass(Number(order.profit))}>{formatCurrency(order.profit)}</p>
                </div>
              </div>

              <div className="flex justify-between items-center">
                <p className={`${order.side === 'BUY' ? 'text-green-700' : 'text-red-500'}`}>{order.side}</p>
                <p>{order.type}</p>
              </div>

              <div className="flex justify-between items-center">
                <div className="flex items-center gap-2">
                  <div className="col-span-2 flex items-center justify-center">
                    <p className={`inline-flex rounded-full border py-1 px-3 text-sm font-medium uppercase ${order.positionSide === 'LONG' ? 'border-[#3CA745] text-[#3CA745]' : 'border-[#FF5733] text-[#FF5733]'}`}>{order.positionSide}</p>
                  </div>
                  <p>{formatCurrency(order.avgPrice)}</p>
                </div>
                <p>{formatDate(order.time)}</p>
              </div>

              {/* <div className="flex flex-col items-start gap-2">
                <p className="font-semibold text-black dark:text-white"> {`${order.origQty} ${order.symbol} (${Number(order.origQty) * Number(order.avgPrice)} $)`}</p>
                <p className={`${order.side === 'BUY' ? 'text-green-700' : 'text-red-500'}`}>{order.side}</p>
                <div className="col-span-2 flex items-center justify-center">
                  <p className={`inline-flex rounded-full border py-1 px-3 text-sm font-medium uppercase ${order.positionSide === 'LONG' ? 'border-[#3CA745] text-[#3CA745]' : 'border-[#FF5733] text-[#FF5733]'}`}>{order.positionSide}</p>
                </div>
              </div> */}

              {/* <div className="flex flex-col items-end gap-2">
                <p>{order.status}</p>
                <p className={`${order.type === 'TAKE_PROFIT' ? 'text-green-700' : 'text-red-500'}`}>{order.type}</p>
                <p>{formatDate(order.time)}</p>
              </div> */}
            </div>
          ))}
          {orderHistory.length > 0 && (
            <Pagination
              pageCount={pageCount}
              onPageChange={handleOrderHistoryPageChange}
              previousLabel={'<'}
              nextLabel={'>'}
              pageRangeDisplayed={1}
              marginPagesDisplayed={1}
            />
          )}
        </div>
      }
    />
  );
};

interface TasksProps {
  keys: KEY[];
  totalCount: number;
  fetchKeys: (page: number) => void;
  onPageChange: (selectedItem: { selected: number }) => void;
  currentPage: number;
  setKeys: React.Dispatch<React.SetStateAction<KEY[]>>;
}

const TableApiKeys: React.FC<TasksProps> = ({ keys, totalCount, fetchKeys, onPageChange, currentPage, setKeys }) => {
  const {
    isModalDeleteOpen,
    isModalEditOpen,
    selectedKey,
    isModalHistoryOrders,
    toggleModalHistoryOrders,
    handleHistoryOrdersKey,
    toggleModalDelete,
    toggleModalEdit,
    handleDeleteKey,
    handleDeleteConfirm,
    handleSaveUpdate,
    handleEditKey,
    orderHistory,
    orderHistoryPage,
    orderHistoryTotalCount,
    handleOrderHistoryPageChange,
  } = useApiKeys();

  const keysPerPage = 10;
  const pageCount = Math.ceil((totalCount || 0) / keysPerPage);

  const offset = currentPage * keysPerPage;
  //const currentKeys = keys.slice(offset, offset + keysPerPage);

  const memoizedKeys = useMemo(() => keys, [keys])

  useEffect(() => {
    // Обновляем таблицу при изменении ключей
    console.log("keys:", keys);
  }, [memoizedKeys]);

  return (
    <>
      <Toaster position="bottom-center" richColors />
      <div className="overflow-hidden rounded-[10px]">
        <div className="max-w-full overflow-x-auto">
          <div className="min-w-[1170px]">

            <div className="grid grid-cols-12 gap-4 bg-[#F9FAFB] px-5 py-4 dark:bg-meta-4 lg:px-7.5 2xl:px-11">
              <div className="col-span-1">
                <h5 className="font-medium text-[#637381] dark:text-bodydark">#</h5>
              </div>
              <div className="col-span-2">
                <h5 className="font-medium text-[#637381] dark:text-bodydark">NAME</h5>
              </div>
              <div className="col-span-2">
                <h5 className="font-medium text-[#637381] dark:text-bodydark">ACCOUNT TYPE</h5>
              </div>
              <div className="col-span-2">
                <h5 className="font-medium text-[#637381] dark:text-bodydark">BALANCE</h5>
              </div>
              <div className="col-span-2">
                <h5 className="font-medium text-[#637381] dark:text-bodydark">BOT NAME</h5>
              </div>
              <div className="col-span-2">
                <h5 className="font-medium text-[#637381] dark:text-bodydark">ACTIONS</h5>
              </div>
            </div>

            <div className="bg-white dark:bg-boxdark">
              {keys == null || keys.length === 0 ? (
                <NotFound />
              ) : (
                memoizedKeys.map((memoizedKey, index) => (
                  <Row
                    isModalHistoryOrders={isModalHistoryOrders}
                    toggleModalHistoryOrders={toggleModalHistoryOrders}
                    handleHistoryOrdersKey={handleHistoryOrdersKey}
                    key={memoizedKey.id}
                    keyItem={memoizedKey}
                    index={index}
                    currentPage={currentPage}
                    keysPerPage={keysPerPage}
                    handleEditKey={handleEditKey}
                    handleDeleteKey={handleDeleteKey}
                    isModalEditOpen={isModalEditOpen}
                    toggleModalEdit={toggleModalEdit}
                    isModalDeleteOpen={isModalDeleteOpen}
                    toggleModalDelete={toggleModalDelete}
                    selectedKey={selectedKey}
                    handleDeleteConfirm={handleDeleteConfirm}
                    handleSaveUpdate={handleSaveUpdate}
                    updateKeys={() => fetchKeys(currentPage)}
                    orderHistory={orderHistory} 
                    orderHistoryPage={orderHistoryPage} 
                    orderHistoryTotalCount={orderHistoryTotalCount} 
                    handleOrderHistoryPageChange={handleOrderHistoryPageChange} 
                  />
                ))
              )}
            </div>

          </div>
        </div>
      </div>

      {memoizedKeys && memoizedKeys.length > 0 && 
        <Pagination
          pageCount={pageCount}
          onPageChange={onPageChange}
        />
      }
    </>
  );
};

const Row = ({
  keyItem,
  index,
  currentPage,
  isModalHistoryOrders,
  toggleModalHistoryOrders,
  handleHistoryOrdersKey,
  keysPerPage,
  handleEditKey,
  handleDeleteKey,
  isModalEditOpen,
  toggleModalEdit,
  isModalDeleteOpen,
  toggleModalDelete,
  selectedKey,
  handleDeleteConfirm,
  handleSaveUpdate,
  updateKeys,
  orderHistory, 
  orderHistoryPage, 
  orderHistoryTotalCount, 
  handleOrderHistoryPageChange, 
}: {
  keyItem: KEY;
  index: number;
  currentPage: number;
  keysPerPage: number;
  handleEditKey: (key: KEY) => void;
  handleDeleteKey: (key: KEY) => void;
  isModalEditOpen: boolean;
  isModalHistoryOrders: boolean;
  toggleModalHistoryOrders: () => void;
  handleHistoryOrdersKey: (key: KEY) => void;
  toggleModalEdit: () => void;
  isModalDeleteOpen: boolean;
  toggleModalDelete: () => void;
  selectedKey: KEY | null;
  handleDeleteConfirm: (result: boolean) => void;
  handleSaveUpdate: (result: boolean) => void;
  updateKeys: () => void;
  orderHistory: OrderHistory[]; 
  orderHistoryPage: number; 
  orderHistoryTotalCount: number; 
  handleOrderHistoryPageChange: (selectedItem: { selected: number }) => void; 
}) => {
  const navigate = useNavigate();

  const handleNavigateToManualTrading = () => {
    navigate(`/manual-trading/${keyItem.id}`);
  };

  return (
    <div className="grid grid-cols-12 gap-4 border-t border-[#EEEEEE] px-5 py-4 dark:border-strokedark lg:px-7.5 2xl:px-11">
      <div className="col-span-1">
        <div className="flex gap-3 items-center">
          <p className="text-[#637381] dark:text-bodydark">{currentPage * keysPerPage + index + 1}</p>
        </div>
      </div>
      <div className="col-span-2">
        <p className="text-[#637381] dark:text-bodydark">{keyItem.name}</p>
      </div>
      <div className="col-span-2">
        <p className="text-[#637381] dark:text-bodydark">{keyItem.exchange_name}</p>
      </div>
      <div className="col-span-2">
        <p className="text-[#637381] dark:text-bodydark">{formatCurrency(keyItem.infoBingX.accountFuture.balance)}</p>
      </div>
      <div className="col-span-2">
        <p className="text-[#637381] dark:text-bodydark">{`${keyItem.bot.name ? keyItem.bot.name : '------'}`}</p>
      </div>
      <div className="col-span-2 flex items-center gap-4">

        <BsClockHistory
          className="cursor-pointer duration-300 ease-in-out hover:text-primary text-xl"
          onClick={() => {
            handleHistoryOrdersKey(keyItem)
          }}
        />

        <ModalSideRight
          isOpen={isModalHistoryOrders}
          toggleModal={toggleModalHistoryOrders}
          title="History Orders"
          content={
            selectedKey && (
              <HistoryOrdersModal
                isOpen={isModalHistoryOrders}
                toggleModal={toggleModalHistoryOrders}
                keyItem={selectedKey}
                orderHistory={orderHistory} 
                orderHistoryPage={orderHistoryPage} 
                orderHistoryTotalCount={orderHistoryTotalCount} 
                handleOrderHistoryPageChange={handleOrderHistoryPageChange} 
              />
            )
          }
        />

        <PiTrademarkFill
          className="cursor-pointer duration-300 ease-in-out hover:text-primary text-xl"
          onClick={handleNavigateToManualTrading}
        />

        <BsFillPencilFill
          className="cursor-pointer duration-300 ease-in-out hover:text-primary text-xl"
          onClick={() => handleEditKey(keyItem)}
        />

        <ModalSideRight
          isOpen={isModalEditOpen}
          toggleModal={toggleModalEdit}
          title="EDIT KEY"
          content={
            selectedKey && (
              <Key
                toggleModal={toggleModalEdit}
                action="edit"
                keyItem={selectedKey}
                onSave={handleSaveUpdate}
                updateKeys={updateKeys} />
            )
          } />

        <BsFillTrashFill
          className="cursor-pointer duration-300 ease-in-out hover:text-primary text-xl"
          onClick={() => handleDeleteKey(keyItem)}
        />

        <ModalCenter
          isOpen={isModalDeleteOpen}
          toggleModal={toggleModalDelete}
          content={
            selectedKey && (
              <ContentConfirm
                toggleModal={toggleModalDelete}
                text="Are you sure you want to delete this user?"
                onHandle={() => handleDeleteConfirm}
                updateItems={updateKeys}
              />
            )
          }
        />
      </div>
    </div>
  );
};

export default TableApiKeys;