
export const types = [
  {
    value: "Market",
    label: "Market",
  },
  {
    value: "Limit",
    label: "Limit",
  },
  {
    value: "Take Profit",
    label: "Take Profit",
  },
  {
    value: "Stop Loss",
    label: "Stop Loss",
  },
];