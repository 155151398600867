import React, { useState } from 'react';
import { BsFillTrashFill, BsFillPencilFill, BsChevronDown, BsChevronUp, BsPlusCircleFill } from "react-icons/bs";
import { IoSettings } from "react-icons/io5";
import { Toaster, toast } from 'sonner';
import NotFound from '../../../common/NotFound';
import Switcher from "../../../components/Switchers/Switcher";
import { Db_Bots } from '../../../types/botsAll';
import { useBotsAll } from '../../../hooks/useBotsAll';
import ModalSideRight from '../../../components/Modals/ModalSideRight';
import Bot from './Bot';
import { useNavigate } from 'react-router-dom';
import Pagination from '../../../components/Pagination/Pagination';
import ModalCenter from '../../../components/Modals/ModalCenter';
import ContentConfirm from '../../../components/Modals/ContentConfirm';
import { formatCurrency } from '../../../utils/formatCurrency';
import BtnPrimary from '../../../components/Buttons/BtnPrimary';
import AttachBot from './AttachDetachBot';

interface BotsAllProps {
  bots: Db_Bots[];
  updateBots: (page: number) => void;
  totalCount: number;
  onPageChange: (selectedItem: { selected: number }) => void;
  currentPage: number;
}

const TableBotsAll: React.FC<BotsAllProps> = ({ bots, updateBots, totalCount, onPageChange, currentPage }) => {
  const { 
    handleActiveChange, 
    isModalAttachKeyOpen,
    toggleModalAttachKey,
    handleAttachKey,
    botsAll,
    isModalEditOpen,
    toggleModalEdit,
    selectedBot,
    handleEditBot,
    handleSaveUpdate,
    isModalConfirmOpen,
    handleApiKeyDetach,
    toggleModalConfirm,
    handleActivateConfirm,
    setConfirmBotUuid,
    switcherStates,
    setSwitcherStates,
    setSelectedBot,
  } = useBotsAll();

  const [expandedRows, setExpandedRows] = useState<string[]>([]);
  const navigate = useNavigate();

  const handleNavigateToBotSettings = (bot: Db_Bots) => {
    navigate(`/bot/${bot.uuid}`);
  };

  const pageCount = Math.ceil((totalCount || 0) / 10);

  const toggleRowExpansion = (id: string) => {
    setExpandedRows(prevExpandedRows => 
      prevExpandedRows.includes(id) 
        ? prevExpandedRows.filter(rowId => rowId !== id) 
        : [...prevExpandedRows, id]
    );
  };

  const handleSwitcherChange = async (uuid: string, isActive: boolean) => {
    const bot = botsAll.find(bot => bot.uuid === uuid);
    if (bot) {
      setSelectedBot(bot);
    }
    setConfirmBotUuid(uuid);
    toggleModalConfirm();
  };

  const handleDetachConfirm = (botId: string) => {
    setSelectedBot(botsAll.find(bot => bot._id === botId) || null);
    toggleModalConfirm();
  };

  return (
    <>
      <Toaster position="bottom-center" richColors />
      <div className="overflow-hidden rounded-[10px]">
        <div className="max-w-full overflow-x-auto">
          <div className="min-w-[1170px]">

            <div className="grid grid-cols-12 gap-4 bg-[#F9FAFB] px-5 py-4 dark:bg-meta-4 lg:px-7.5 2xl:px-11">
              <div className="col-span-2">
                <h5 className="font-medium text-[#637381] dark:text-bodydark">NAME</h5>
              </div>
              <div className="col-span-1">
                <h5 className="font-medium text-[#637381] dark:text-bodydark">ACTIVE</h5>
              </div>
              <div className="col-span-2">
                <h5 className="font-medium text-[#637381] dark:text-bodydark">DATE</h5>
              </div>
              <div className="col-span-3">
                <h5 className="font-medium text-[#637381] dark:text-bodydark">API</h5>
              </div>
              <div className="col-span-2">
                <h5 className="font-medium text-[#637381] dark:text-bodydark">POSITIONS</h5>
              </div>
              <div className="col-span-2">
                <h5 className="font-medium text-[#637381] dark:text-bodydark">ACTIONS</h5>
              </div>
            </div>

            <div className="bg-white dark:bg-boxdark">

              {bots && bots.length === 0 ? (
                <NotFound />
              ) : (bots && bots.map((bot, key) => {
                const isExpanded = expandedRows.includes(bot._id);
                const switcherState = switcherStates[bot.uuid] !== undefined ? switcherStates[bot.uuid] : bot.active;

                return (
                  <div className="border-t border-[#EEEEEE] dark:border-strokedark" key={key}>
                    <div className="grid grid-cols-12 gap-4 px-5 py-4 lg:px-7.5 2xl:px-11">
                      <div className="col-span-2 flex items-start">
                        <p className="text-[#637381] dark:text-bodydark">{bot.name}</p>
                      </div>

                      <div className="col-span-1 flex items-start gap-2">
                        <Switcher 
                          isActive={switcherState} 
                          onChange={() => handleSwitcherChange(bot.uuid, switcherState)} 
                          id={`isActiveBot-${bot.uuid}`} 
                        />

                        <ModalCenter
                          isOpen={isModalConfirmOpen}
                          toggleModal={toggleModalConfirm}
                          content={
                            <ContentConfirm
                              toggleModal={toggleModalConfirm}
                              text="Are you sure you want to change the bot status?"
                              onHandle={handleActivateConfirm}
                              updateItemsPagination={() => updateBots(currentPage)}
                            />
                          }
                        />
                      </div>

                      <div className="col-span-2 flex items-start">
                        {new Date(bot.dateActive).toLocaleString()}
                      </div>
                      <div className={`col-span-3 flex flex-col items-start justify-start overflow-hidden transition-all duration-300 ${isExpanded ? 'max-h-96' : 'max-h-12'}`}>
                        {bot.api.name ? 
                          <>
                            <p className="text-[#637381] dark:text-bodydark font-bold">{bot.api.name}</p>
                            <p className="text-[#637381] dark:text-bodydark">Wallet balance: {formatCurrency(bot.api?.infoBingX?.accountFuture?.balance)}</p>
                            <p className="text-[#637381] dark:text-bodydark">Realized profit: {bot.api?.infoBingX?.accountFuture?.realisedProfit} %</p>
                            <p className="text-[#637381] dark:text-bodydark">Equity: {formatCurrency(bot.api?.infoBingX?.accountFuture?.equity)}</p>
                            <p className="text-[#637381] dark:text-bodydark">Unrealized profit: {bot.api?.infoBingX?.accountFuture?.unrealizedProfit} %</p>
                            <BtnPrimary
                              className='w-auto mt-2'
                              onClick={() => handleApiKeyDetach(bot._id)}
                            >
                              <BsFillTrashFill /> Detach API Key
                            </BtnPrimary>

                            {/* <ModalCenter
                              isOpen={isModalConfirmOpen}
                              toggleModal={toggleModalConfirm}
                              content={
                                selectedBot && (
                                  <ContentConfirm
                                    toggleModal={toggleModalConfirm}
                                    text="Are you sure you want to detach the API key?"
                                    onHandle={handleDetachKey} // Передаем handleDetachKey
                                    updateItemsPagination={() => updateBots(currentPage)}
                                  />
                                )
                              }
                            /> */}
                          </> : 
                          <>
                            <BtnPrimary
                              className='w-auto'
                              onClick={() => {
                                setSelectedBot(bot);
                                toggleModalAttachKey();
                              }}
                            >
                              <BsPlusCircleFill /> Attach API Key
                            </BtnPrimary>

                            <ModalCenter
                              isOpen={isModalAttachKeyOpen}
                              toggleModal={toggleModalAttachKey}
                              content={
                                selectedBot && (
                                  <AttachBot
                                    bot={selectedBot}
                                    onAttach={(result) => {
                                      handleAttachKey(result);
                                      updateBots(currentPage);
                                    }}
                                    onClose={toggleModalAttachKey}
                                  />
                                )
                              }
                            />
                          </>
                        }
                      </div>
                      <div className={`col-span-2 flex flex-col items-start overflow-hidden transition-all duration-300 ${isExpanded ? 'max-h-96' : 'max-h-12'}`}>
                        {bot.setting?.leverage ? 
                          <>
                            <p className="text-[#637381] dark:text-bodydark"><span className='font-bold'>Leverage: </span>{bot.setting?.leverage} </p>
                          </> : 
                          <p className='text-[#637381] dark:text-bodydark'>--------</p>
                        }
                      </div>
                      <div className="col-span-2 flex items-start justify-start gap-4">
                        <BsFillPencilFill
                          className="cursor-pointer duration-300 ease-in-out hover:text-primary"
                          onClick={() => handleEditBot(bot)}
                        />

                        <ModalSideRight
                          isOpen={isModalEditOpen}
                          toggleModal={toggleModalEdit}
                          title="EDIT BOT"
                          content={
                            selectedBot && (
                              <Bot
                                toggleModal={toggleModalEdit}
                                item={selectedBot}
                                onSave={handleSaveUpdate}
                                updateBots={() => updateBots(currentPage)} />
                            )
                          } />

                        <IoSettings
                          className="cursor-pointer duration-300 ease-in-out hover:text-primary"
                          onClick={() => handleNavigateToBotSettings(bot)}
                        />

                        {isExpanded ? (
                          <BsChevronUp
                            className="cursor-pointer duration-300 ease-in-out hover:text-primary"
                            onClick={() => toggleRowExpansion(bot._id)}
                          />
                        ) : (
                          <BsChevronDown
                            className="cursor-pointer duration-300 ease-in-out hover:text-primary"
                            onClick={() => toggleRowExpansion(bot._id)}
                          />
                        )}
                      </div>
                    </div>
                  </div>
                );
              }))}

            </div>

          </div>
        </div>
      </div>

      { bots && bots.length === 0 ? <></> : 
        <Pagination
          pageCount={pageCount}
          onPageChange={onPageChange}
        />
      }

    </>
  );
};

export default TableBotsAll;