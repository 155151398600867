import React from 'react';

interface TextareaFieldProps {
  label?: string;
  value: string;
  onChange: (e: React.ChangeEvent<HTMLTextAreaElement>) => void;
  placeholder?: string;
  className?: string;
  rows?: number;
}

const TextareaField: React.FC<TextareaFieldProps> = ({
  label = "Comment",
  value,
  onChange,
  placeholder = 'Enter comment',
  className = '',
  rows = 6,
}) => {
  return (
    <div className={`mb-4 ${className}`}>
      <label className="mb-3 block text-[#637381] dark:text-white font-medium">
        {label}
      </label>
      <textarea
        rows={rows}
        placeholder={placeholder}
        value={value}
        onChange={onChange}
        className="w-full rounded-lg border-[1.5px] border-stroke bg-transparent py-3 px-5 text-black outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:text-white dark:focus:border-primary"
      ></textarea>
    </div>
  );
};

export default TextareaField;