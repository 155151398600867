import React, { ReactNode } from 'react';

const SignInLayout: React.FC<{ children: ReactNode }> = ({ children }) => {

  return (
    <div className="bg-boxdark-2">
      <div className="flex items-center justify-center min-h-screen bg-gray-100">
        {children}
      </div>
    </div>
  );
};

export default SignInLayout;
