import React from 'react';
import ReactPaginate from 'react-paginate';

interface PaginationProps {
  pageCount: number;
  onPageChange: (selectedItem: { selected: number }) => void;
  previousLabel?: string;
  nextLabel?: string;
  marginPagesDisplayed?: number
  pageRangeDisplayed?: number;
}

const Pagination: React.FC<PaginationProps> = ({ 
  pageCount, 
  onPageChange, 
  previousLabel = "previous", 
  nextLabel= "next",
  pageRangeDisplayed = 5,
  marginPagesDisplayed = 2,
 }) => {
  return (
    <ReactPaginate
      previousLabel={previousLabel}
      nextLabel={nextLabel}
      breakLabel={'...'}
      pageCount={pageCount}
      marginPagesDisplayed={marginPagesDisplayed}
      pageRangeDisplayed={pageRangeDisplayed}
      onPageChange={onPageChange}
      containerClassName={'flex justify-center mt-4'}
      activeClassName={'bg-primary text-white'}
      pageClassName={'mx-1 px-3 py-1 rounded-md bg-gray-200 text-gray-700 hover:bg-gray-300'}
      previousClassName={'mx-1 px-3 py-1 rounded-md bg-gray-200 text-gray-700 hover:bg-gray-300'}
      nextClassName={'mx-1 px-3 py-1 rounded-md bg-gray-200 text-gray-700 hover:bg-gray-300'}
      breakClassName={'mx-1 px-3 py-1 rounded-md bg-gray-200 text-gray-700 hover:bg-gray-300'}
      disabledClassName={'opacity-50 cursor-not-allowed'}
    />
  );
};

export default Pagination;