import React, { useState } from 'react';
import InputField from '../../components/Forms/Inputs/InputField';
import { toast } from 'sonner';
import { BsXCircleFill } from "react-icons/bs";
import BtnPrimary from '../../components/Buttons/BtnPrimary';
import api from '../../utils/api';

interface ContentChangePassProps {
  toggleModal: () => void;
}

const ContentChangePass: React.FC<ContentChangePassProps> = ({ toggleModal }) => {
  const [oldPass, setOldPass] = useState('');
  const [newPass, setNewPass] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const handleOldPassChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setOldPass(e.target.value);
  };

  const handleNewPassChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setNewPass(e.target.value);
  };

  const handleChangePassword = async () => {
    setLoading(true);
    setError(null);

    try {
      const response = await api.post('/api/user/change_password', {
        current_password: oldPass,
        new_password: newPass,
      });

      if (response.data.code === 200) {
        toast.success('Password changed successfully');
        toggleModal();
      } else {
        setError('Failed to change password');
      }
    } catch (err) {
      setError('Failed to change password');
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <div className="text-start">
        <h3 className="mb-5 text-title-md2 font-semibold text-black dark:text-white">
          Change your password
        </h3>

        <InputField
          className='mb-4'
          label="Old password"
          value={oldPass}
          onChange={handleOldPassChange}
          placeholder="*******"
          type="password"
        />

        <InputField
          className='mb-4'
          label="New password"
          value={newPass}
          onChange={handleNewPassChange}
          placeholder="*******"
          type="password"
        />

        {error && <p className="text-red-500 mb-4">{error}</p>}

        <div className='grid grid-cols-2 gap-4'>
          <BtnPrimary
            onClick={handleChangePassword}
            disabled={oldPass === '' || newPass === '' || loading}
          >
            {loading ? 'Changing...' : 'Change'}
          </BtnPrimary>
          <BtnPrimary style='outline' onClick={toggleModal}>
            <BsXCircleFill /> Close
          </BtnPrimary>
        </div>
      </div>
    </>
  );
};

export default ContentChangePass;