import React, { useState, useEffect } from 'react';
import { BsPcDisplay, BsXCircleFill, BsFillPhoneFill } from "react-icons/bs";
import { AiOutlineClear } from "react-icons/ai";
import BtnPrimary from '../../components/Buttons/BtnPrimary';
import axios from 'axios';
import { Session } from '../../types/sessions';
import api from '../../utils/api';
import Loading from '../../common/Loading';
import { toast } from 'sonner';
import NotFound from '../../common/NotFound';

interface ContentActivityHistoryProps {
  toggleModal: () => void;
}

const ContentActivityHistory: React.FC<ContentActivityHistoryProps> = ({ toggleModal }) => {
  const [sessions, setSessions] = useState<Session[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);

  const handleLogoutAll = async () => {
    try {
      await api.post('/api/logout_all');
      toast.success('All sessions logged out successfully');
      setSessions([]); 
    } catch (err) {
      toast.error('Failed to log out all sessions');
    }
  };

  useEffect(() => {
    const fetchActivityHistory = async () => {
      try {
        const response = await api.get<{ result: Session[] }>('/api/sessions');
        setSessions(response.data.result);
        console.log("response.data.result: ", response.data.result)
      } catch (err) {
        setError('Failed to fetch activity history');
      } finally {
        setLoading(false);
      }
    };

    fetchActivityHistory();
  }, []);

  if (loading) {
    return  <Loading />;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  return (
    <div className="flex flex-col h-full">
      <div className="flex-grow mb-4 flex flex-col gap-2">
        {sessions.length === 0 || sessions == null ? (<NotFound />) 
        : (
          sessions.map((session) => (
            <div className='flex items-center justify-between gap-4 rounded-md border border-stroke p-2.5 shadow-1 dark:border-strokedark dark:bg-[#37404F]' key={session.ID}>
              <div className='flex items-center gap-4'>
                {session.UserAgent.includes('Mobile') ? <BsFillPhoneFill className='text-xl' /> : <BsPcDisplay className='text-xl' />}
              </div>
              <div className='flex flex-col items-end'>
                <p>{`${session.City}, ${session.Country}`}</p>
                <p>{`${session.IPAddress} | ${new Date(session.ExpiresAt).toLocaleString()}`}</p>
              </div>
            </div>
          ))
        )}
      </div>

      <div className='flex items-center justify-between gap-4 mt-auto'>
        <BtnPrimary
          className='w-full'
          onClick={() => {
            handleLogoutAll()
            //toggleModal();
          }}>
          <AiOutlineClear /> Clear history
        </BtnPrimary>
        <BtnPrimary
          className='w-full'
          style="outline"
          onClick={() => {
            toggleModal();
          }}>
          <BsXCircleFill /> Close
        </BtnPrimary>
      </div>
    </div>
  );
};

export default ContentActivityHistory;