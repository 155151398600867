import axios from 'axios';
import { ApiResponse, AuthResult } from '../types/api';

// Функция для проверки и обновления токенов
export const checkAuth = async () => {
  const accessToken = localStorage.getItem('accessToken');
  const refreshToken = localStorage.getItem('refreshToken');

  if (!accessToken || !refreshToken) {
    console.log('No accessToken or refreshToken found');
    return false;
  }

  try {
    // Проверка валидности accessToken с помощью запроса к защищенному ресурсу /api/api_keys
    const response = await axios.get<ApiResponse<any>>('/api/api_keys', {
      headers: { Authorization: `Bearer ${accessToken}` },
    });

    // Если запрос успешен, то accessToken валиден
    if (response.data.code === 200) {
      console.log('accessToken is valid');
      return true;
    }
  } catch (error) {
    console.log('accessToken is invalid or expired');
    // Если accessToken невалиден, пытаемся обновить его с помощью refreshToken
    try {
      const refreshResponse = await axios.post<ApiResponse<AuthResult>>('/api/auth/refresh', { refresh_token: refreshToken });

      if (refreshResponse.data.code === 200 && refreshResponse.data.result) {
        localStorage.setItem('accessToken', refreshResponse.data.result.accessToken);
        localStorage.setItem('refreshToken', refreshResponse.data.result.refreshToken);
        console.log('accessToken was successfully refreshed');
        return true;
      }
    } catch (refreshError) {
      console.log('Failed to refresh accessToken');
      return false;
    }
  }

  return false;
};