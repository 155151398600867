import { BsExclamationCircle } from "react-icons/bs";

const NotFound = () => {
  return (
    <div className="flex flex-col items-center justify-center p-5">
      <BsExclamationCircle className="text-4xl text-gray-400 mb-2" />
      <p className="text-gray-600">Not found</p>
    </div>
  );
};

export default NotFound;