import { useState, useEffect, useCallback } from 'react';
import api from '../utils/api';
import { ApiResponse } from '../types/api';
import { Db_Bots } from '../types/botsAll';
import { GetApiKeysAll, KEY } from '../types/key';
import { toast } from 'sonner';

export const useBotsAll = () => {
  const [botsAll, setBotsAll] = useState<Db_Bots[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [selectedBot, setSelectedBot] = useState<Db_Bots | null>(null);
  const [totalCount, setTotalCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const [isModalConfirmOpen, setIsModalConfirmOpen] = useState(false);
  const [confirmBotUuid, setConfirmBotUuid] = useState<string | null>(null);
  const [switcherStates, setSwitcherStates] = useState<{ [uuid: string]: boolean }>({});
  const [isModalAttachKeyOpen, setIsModalAttachKeyOpen] = useState(false);
  const [selectedApiKey, setSelectedApiKey] = useState<string | null>(null);

  const [isModalEditOpen, setIsModalEditOpen] = useState(false);

  const toggleModalEdit = () => setIsModalEditOpen(!isModalEditOpen);
  const toggleModalConfirm = () =>  setIsModalConfirmOpen(!isModalConfirmOpen);
  const toggleModalAttachKey = () => setIsModalAttachKeyOpen(!isModalAttachKeyOpen);

  useEffect(() => {
    getBotsAll(currentPage);
  }, []);

  const handleAttachKey = async (result: boolean) => {
    if (result && selectedBot?._id) {
      await getBotsAll(currentPage);
    }
    toggleModalAttachKey();
  };
  
  

  const handleActivateConfirm = async (result: boolean) => {
    if (result && confirmBotUuid) {
      handleActiveChange(confirmBotUuid, switcherStates[confirmBotUuid]);
    }
    toggleModalConfirm();
  };

  const getBotsAll = useCallback(async (page: number) => {
    try {
      const response = await api.get(`/bot-api/bots?page=${page + 1}&pageSize=10`);
      if (response.data && response.data.result) {
        setBotsAll(response.data.result.items);
        setTotalCount(response.data.result.totalCount);
        // Инициализируем состояние switcherStates на основе botsAll
        const initialSwitcherStates: { [uuid: string]: boolean } = {};
        response.data.result.items.forEach((bot: Db_Bots) => {
          initialSwitcherStates[bot.uuid] = bot.active;
        });
        setSwitcherStates(initialSwitcherStates);
      } else {
        setBotsAll([]);
        setTotalCount(0);
      }
    } catch (error) {
      console.error('Error fetching keys:', error);
    } finally {
      setIsLoading(false);
    }
  }, [api, setBotsAll, setTotalCount]);

  const activateBot = async (uuid: string) => {
    try {
      const response = await api.post(`/bot-api/bots/${uuid}/activate`);
      if (response.data.code === 200 && response.data.result) {
        const bot = botsAll.find(bot => bot.uuid === confirmBotUuid);
        toast.success(`Bot ${bot?.name} activated successfully`);
      } else {
        setError('Failed to activate bot');
      }
    } catch (error) {
      setError('An error occurred while activating bot');
    }
  };
  
  const deactivateBot = async (uuid: string) => {
    try {
      const response = await api.post(`/bot-api/bots/${uuid}/deactivate`);
      if (response.data.code === 200 && response.data.result) {
        const bot = botsAll.find(bot => bot.uuid === confirmBotUuid);
        toast.success(`Bot ${bot?.name} deactivated successfully`);
      } else {
        setError('Failed to deactivate bot');
      }
    } catch (error) {
      setError('An error occurred while deactivating bot');
    }
  };

  const handleActiveChange = (uuid: string, isActive: boolean) => {
    if (isActive) {
      deactivateBot(uuid);
    } else {
      activateBot(uuid);
    }
    // Обновляем состояние switcherStates
    setSwitcherStates(prevStates => ({
      ...prevStates,
      [uuid]: !isActive
    }));
  };

  const handleSaveUpdate = (result: boolean) => {
    if (result) {
      toast.success('Bot edited successfully');
    } else {
      toast.error('Failed to edit bot');
    }
  };

  const handleEditBot = (bot: Db_Bots) => {
    setSelectedBot(bot);
    toggleModalEdit();
  };

  

  const transformKeysToOptions = (keys: KEY[]) => {
    return keys.map(key => ({
      value: key.id,
      label: key.name
    }));
  };

  const handlePageChange = (selectedItem: { selected: number }) => {
    setCurrentPage(selectedItem.selected);
    getBotsAll(selectedItem.selected);
  };

  const handleApiKeyDetach = async (botId: string) => {
    console.log("selectedApiKey: ", selectedApiKey)

    try {
      const response = await api.post(`/bot-api/bots/detach-api-key/${botId}`, {
        api_key_id: selectedApiKey
      });

      if (response.data.code === 200 && response.data.result) {
        toast.success('API key detached successfully');
        setSelectedApiKey(null); 
        getBotsAll(currentPage) 
      } else {
        setError('Failed to detach API key');
        toast.success('Failed to detach API key');
      }
    } catch (error) {
      setError('An error occurred while detaching API key');
    }
  };


  return {
    isLoading,
    error,
    botsAll,
    selectedBot,
    isModalEditOpen,
    isModalAttachKeyOpen,
    totalCount,
    currentPage,
    handleApiKeyDetach,
    handlePageChange,
    handleEditBot,
    toggleModalEdit,
    setBotsAll,
    getBotsAll,
    setError,
    handleActiveChange,
    handleSaveUpdate,
    activateBot,
    deactivateBot,
    setSelectedBot,
    transformKeysToOptions,
    isModalConfirmOpen,
    toggleModalConfirm,
    toggleModalAttachKey,
    handleActivateConfirm,
    setConfirmBotUuid,
    switcherStates,
    setSwitcherStates,
    handleAttachKey,
    selectedApiKey,
    setSelectedApiKey,
  };
};