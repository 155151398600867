import React, { useState } from 'react';
import { Toaster, toast } from 'sonner';
import { BsPlusCircleFill, BsXCircleFill } from "react-icons/bs";
import InputField from '../../../components/Forms/Inputs/InputField';
import { Db_Bots } from '../../../types/botsAll';
import BtnPrimary from '../../../components/Buttons/BtnPrimary';
import api from '../../../utils/api';

interface BotProps {
  toggleModal: () => void;
  item?: Db_Bots;
  onSave?: (result: boolean) => void;
  updateBots?: () => void;
}

const Bot: React.FC<BotProps> = ({ toggleModal, item, onSave, updateBots }) => {
  const [name, setName] = useState(item?.name || '');
  const [error, setError] = useState<string | null>(null);

  const handleNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setName(e.target.value);
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    if (name === '') {
      toast.error('Please fill fields form');
      return;
    }

    const botData: Partial<Db_Bots> = {
      name: name,
    };

    try {
      const response = await api.put(`/bot-api/bots/${item?.uuid}`, botData);
      if (response.data.code === 200 && response.data.result) {
        toast.success('Bot updated successfully');
        updateBots?.(); 
        toggleModal();
        onSave?.(true);
      } else {
        setError('Failed to update bot');
        onSave?.(false);
      }
    } catch (error) {
      setError('An error occurred while updating bot');
      onSave?.(false);
    }
  };

  return (
    <>
      <form onSubmit={handleSubmit} className="flex flex-col h-full">
        <div className="flex-grow mb-2">
          <InputField
            label="Name"
            value={name}
            onChange={handleNameChange}
            placeholder="Enter name user"
            className="mb-4"
          />
        </div>

        <div className='flex items-center justify-between gap-4 mt-auto'>
          <BtnPrimary 
            className='w-full' 
            type='submit' >
            <BsPlusCircleFill />  Edit bot
          </BtnPrimary>
          <BtnPrimary
            className='w-full'
            style="outline"
            onClick={() => {
              toggleModal();
            }}>
            <BsXCircleFill /> Close
          </BtnPrimary>
        </div>
      </form>
      {error && <p className="text-red-500 mt-4">{error}</p>}
    </>
  );
};

export default Bot;