// AddOrder.tsx
import React from 'react';
import MultiSelect from '../../components/Forms/Select/MultiSelect';
import InputField from '../../components/Forms/Inputs/InputField';
import SimpleSelect from '../../components/Forms/Select/SimpleSelect';
import BtnPrimary from '../../components/Buttons/BtnPrimary';
import { useManualTrading } from '../../hooks/useManualTrading';
import { symbols } from '../../data/symbols';
import { types } from '../../data/typesMarket';
import { useParams } from 'react-router-dom';

const AddOrder: React.FC = () => {
  const { keyId } = useParams<{ keyId: string }>();

  const {
    symbol,
    type,
    leverage,
    amount,
    takeProfit,
    stopLoss,
    position,
    price,
    handleLeverageChange,
    handleSelectChange,
    handleTypeChange,
    handleAmountChange,
    handleTakeProfitChange,
    handleStopLossChange,
    handlePriceChange,
    handleSubmit,
  } = useManualTrading(keyId);

  const renderAdditionalFields = () => {
    switch (type) {
      case 'Market':
        return (
          <FieldMarket
            amount={amount} handleAmountChange={handleAmountChange}
            takeProfit={takeProfit} handleTakeProfitChange={handleTakeProfitChange}
            stopLoss={stopLoss} handleStopLossChange={handleStopLossChange} />
        );
      case 'Limit':
        return (
          <FieldLimit
            amount={amount} handleAmountChange={handleAmountChange}
            takeProfit={takeProfit} handleTakeProfitChange={handleTakeProfitChange}
            stopLoss={stopLoss} handleStopLossChange={handleStopLossChange}
            price={price} handlePriceChange={handlePriceChange} />
        );
      case 'Take Profit':
        return (
          <FieldTakeProfit
            amount={amount} handleAmountChange={handleAmountChange}
            takeProfit={takeProfit} handleTakeProfitChange={handleTakeProfitChange}
            stopLoss={stopLoss} handleStopLossChange={handleStopLossChange} />
        );
      case 'Stop Loss':
        return (
          <FieldStopLose
            amount={amount} handleAmountChange={handleAmountChange}
            takeProfit={takeProfit} handleTakeProfitChange={handleTakeProfitChange}
            stopLoss={stopLoss} handleStopLossChange={handleStopLossChange} />
        );
      default:
        return null;
    }
  };

  return (
    <div className='flex flex-col items-center justify-start mb-4 rounded-lg border border-stroke bg-white dark:border-strokedark dark:bg-boxdark px-3 py-2.5 shadow-default h-full'>

      <div className='grid grid-cols-1 md:grid-cols-3 gap-2 w-full mb-4'>
        <div className='md:col-span-2'>
          <MultiSelect
            className='w-full'
            label='Choose symbol'
            options={symbols}
            onChange={handleSelectChange}
          />
        </div>

        <div className='md:col-span-1'>
          <InputField
            label="Leverage"
            value={leverage === '' ? '' : leverage}
            onChange={handleLeverageChange}
            placeholder="Leverage"
            type="number"
            className="w-full"
          />
        </div>
      </div>

      <SimpleSelect
        options={types}
        value={type}
        onChange={handleTypeChange}
        className="w-full mb-2"
      />

      {renderAdditionalFields()}

      <div className='flex items-center gap-2 mt-4'>
        <BtnPrimary style='green' onClick={() => handleSubmit('LONG')}>
          <p className='text-sm'>BUY/LONG</p>
        </BtnPrimary>
        <BtnPrimary style='red' onClick={() => handleSubmit('SHORT')}>
          <p className='text-sm'>SELL/SHORT</p>
        </BtnPrimary>
      </div>
    </div>
  );
};

export default AddOrder;

interface FieldProps {
  amount: number | '';
  handleAmountChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  takeProfit: number | '';
  handleTakeProfitChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  stopLoss: number | '';
  handleStopLossChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  price?: number | '';
  handlePriceChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

const FieldMarket: React.FC<FieldProps> = ({
  amount, handleAmountChange,
  takeProfit, handleTakeProfitChange,
  stopLoss, handleStopLossChange,
}) => {
  return (
    <div className="w-full">
      <div className='flex items-center gap-2 mb-4'>
        <InputField
          label="Amount"
          value={amount}
          onChange={handleAmountChange}
          placeholder="Amount"
          type="number"
          className="w-full"
        />

        <InputField
          label="Take Profit"
          value={takeProfit}
          onChange={handleTakeProfitChange}
          placeholder="Take Profit"
          type="number"
          className="w-full"
        />
      </div>

      <InputField
        label="Stop Loss"
        value={stopLoss}
        onChange={handleStopLossChange}
        placeholder="Stop Loss"
        type="number"
        className="w-full"
      />
    </div>
  );
};

const FieldLimit: React.FC<FieldProps> = ({
  amount, handleAmountChange,
  takeProfit, handleTakeProfitChange,
  stopLoss, handleStopLossChange,
  price, handlePriceChange,
}) => {
  return (
    <div className="w-full">
      <div className='flex items-center gap-2 mb-4'>
        <InputField
          label="Amount"
          value={amount}
          onChange={handleAmountChange}
          placeholder="Amount"
          type="number"
          className="w-full"
        />

        <InputField
          label="Price"
          value={price ?? ''}
          onChange={handlePriceChange ?? (() => { })}
          placeholder="Price"
          type="number"
          className="w-full"
        />
      </div>

      <div className='flex items-center gap-2'>
        <InputField
          label="Take Profit"
          value={takeProfit}
          onChange={handleTakeProfitChange}
          placeholder="Take Profit"
          type="number"
          className="w-full"
        />

        <InputField
          label="Stop Loss"
          value={stopLoss}
          onChange={handleStopLossChange}
          placeholder="Stop Loss"
          type="number"
          className="w-full"
        />
      </div>
    </div>
  );
};

const FieldTakeProfit: React.FC<FieldProps> = ({ amount, handleAmountChange, takeProfit, handleTakeProfitChange }) => {
  return (
    <div className="w-full">
      <InputField
        label="Amount"
        value={amount}
        onChange={handleAmountChange}
        placeholder="Amount"
        type="number"
        className="w-full mb-4"
      />
      <InputField
        label="Take Profit"
        value={takeProfit}
        onChange={handleTakeProfitChange}
        placeholder="Take Profit"
        type="number"
        className="w-full"
      />
    </div>
  );
};

const FieldStopLose: React.FC<FieldProps> = ({ amount, handleAmountChange, stopLoss, handleStopLossChange }) => {
  return (
    <div className="w-full">
      <InputField
        label="Amount"
        value={amount}
        onChange={handleAmountChange}
        placeholder="Amount"
        type="number"
        className="w-full mb-4"
      />
      <InputField
        label="Stop Loss"
        value={stopLoss}
        onChange={handleStopLossChange}
        placeholder="Stop Loss"
        type="number"
        className="w-full"
      />
      {/* <InputField
        label="Take Profit"
        value={takeProfit}
        onChange={handleTakeProfitChange}
        placeholder="Take Profit"
        type="number"
        className="w-full"
      /> */}
    </div>
  );
};