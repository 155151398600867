import React from 'react';
import Breadcrumb from '../../components/Breadcrumb/Breadcrumb';
import TableUsers from './TableUsers';
import ModalSideRight from '../../components/Modals/ModalSideRight';
import Loader from '../../common/Loader/index';
import { Toaster, toast } from 'sonner';
import BtnPrimary from '../../components/Buttons/BtnPrimary';
import { BsPlusCircleFill } from 'react-icons/bs';
import User from './User';
import { useUsers } from '../../hooks/useUsers';

const Users: React.FC = () => {
  const { users, isLoading, getUsers, isModalAddUserOpen, toggleModalAddUser } = useUsers();

  const handleAddUserSave = (result: boolean) => {
    if (result) {
      toast.success('User added successfully');
    } else {
      toast.error('Failed to add user');
    }
  };

  if (isLoading) {
    return <Loader />;
  }

  return (
    <>
      <Toaster position="bottom-center" richColors />
      <Breadcrumb pageName='Users' />

      <BtnPrimary onClick={toggleModalAddUser} className='mb-4'>
        <BsPlusCircleFill /> Add user
      </BtnPrimary>
      
      <ModalSideRight
        isOpen={isModalAddUserOpen}
        toggleModal={toggleModalAddUser}
        title="ADD USER"
        content={
          <User
            action="add"
            toggleModal={toggleModalAddUser}
            onSave={handleAddUserSave}
            updateUsers={getUsers}
             />
        }
      />

      <TableUsers users={users} updateUsers={getUsers} />
    </>
  );
};

export default Users;