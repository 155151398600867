import React, {useState} from 'react'
import BtnPrimary from "../../components/Buttons/BtnPrimary";
import InputField from '../../components/Forms/Inputs/InputField';
import SimpleSelect from "../../components/Forms/Select/SimpleSelect";
import { BsFillLockFill } from "react-icons/bs";


const positions = [
  {
    value: "LONG",
    label: "LONG",
  },
  {
    value: "SHORT",
    label: "SHORT",
  },
]


const ContentModalClose: React.FC = () => {
  const [position, setPosition] = useState(positions[0].value);

  const handlePositionChange = (value: string) => {
    setPosition(value);
  };


  return(
    <>
      <svg
        className="mx-auto mb-4 text-gray-400 w-12 h-12"
        aria-hidden="true"
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 20 20"
      >
        <path
          stroke="currentColor"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
          d="M10 11V6m0 8h.01M19 10a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
        />
      </svg>
      <h3 className="mb-5 text-lg font-normal text-gray-500">
        Are you sure you want to close this order?
      </h3>
      <SimpleSelect
        options={positions}
        value={position}
        onChange={handlePositionChange}
        className="w-full mb-4"
      />

      <InputField
        label="Amount"
        value=""
        onChange={() => {}}
        placeholder="Amount"
        type="number"
        className="w-full mb-4"
      />
      <BtnPrimary style='green' className=''><BsFillLockFill />Close {position}</BtnPrimary>
    </>
  )
}

export default ContentModalClose;

{/* <>
      <svg
        className="mx-auto mb-4 text-gray-400 w-12 h-12 dark:text-gray-200"
        aria-hidden="true"
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 20 20"
      >
        <path
          stroke="currentColor"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
          d="M10 11V6m0 8h.01M19 10a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
        />
      </svg>
      <h3 className="mb-5 text-lg font-normal text-gray-500 dark:text-gray-400">
        Are you sure you want to close this order?
      </h3>
      <SimpleSelect
        options={positions}
        value={position}
        onChange={handlePositionChange}
        className="w-full mb-4"
      />

      <InputField
        label="Amount"
        value=""
        onChange={() => {}}
        placeholder="Amount"
        type="number"
        className="w-full mb-4"
      />
      <BtnPrimary style='green' className=''><BsFillLockFill />Close {position}</BtnPrimary>
    </> */}