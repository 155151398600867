import { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import { UserContext } from '../context/UserContext';
import { useNavigate } from 'react-router-dom';
import { toast } from 'sonner';
import { ApiResponse, AuthResult } from '../types/api';
import { jwtDecode } from 'jwt-decode';

export const useAuth = () => {
  const [error, setError] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { setUser } = useContext(UserContext);
  const navigate = useNavigate();

  const login = async (email: string, password: string) => {
    setIsLoading(true);
    try {
      // Искусственная задержка для имитации ответа от сервера
      await new Promise(resolve => setTimeout(resolve, 2500));

      const response = await axios.post<ApiResponse<AuthResult>>('/api/auth/signin', { email, password });
      if (response.data.code === 200 && response.data.result) {
        const accessToken = response.data.result.accessToken;
        const decodedToken = jwtDecode<{ sub: string; role: string, userAgent: string, }>(accessToken);

        const userData = {
          id: decodedToken.sub,
          email: email,
          role: decodedToken.role,
          userAgent: decodedToken.userAgent,
        };

        setUser(userData);
        localStorage.setItem('user', JSON.stringify(userData));
        localStorage.setItem('accessToken', accessToken);
        localStorage.setItem('refreshToken', response.data.result.refreshToken);
        navigate('/users');
      } else {
        setError('Failed login');
        toast.error('Invalid email or password');
      }
    } catch (error) {
      setError('Failed login');
      toast.error('Invalid email or password');
    } finally {
      setIsLoading(false);
    }
  };

  return { login, error, isLoading };
};