import React, { createContext, useState, useEffect } from 'react';
import api from '../utils/api';
import { TASK } from '../types/task';

export const TasksContext = createContext<{ tasks: TASK[]; uniqueSymbols: string[]; setTasks: React.Dispatch<React.SetStateAction<TASK[]>> }>({
  tasks: [],
  uniqueSymbols: [],
  setTasks: () => {},
});

export const TasksProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [tasks, setTasks] = useState<TASK[]>([]);
  const [uniqueSymbols, setUniqueSymbols] = useState<string[]>([]);

  useEffect(() => {
    const fetchTasks = async () => {
      try {
        const user = JSON.parse(localStorage.getItem('user') || '{}');
        if (!user || !user.token) {
          return; 
        }

        const response = await api.get<TASK[]>('/get-tasks');
        setTasks(response.data);
      } catch (error) {
        console.log(error);
      }
    };
    fetchTasks();
  }, []);

  // useEffect(() => {
  //   if (tasks?.length > 0) {
  //     // const symbols = Array.from(new Set(tasks.map(task => task.symbol.map(s => s.name)).flat()));
  //     setUniqueSymbols(symbols);
  //   }
  // }, [tasks]);

  return (
    <TasksContext.Provider value={{ tasks, uniqueSymbols, setTasks }}>
      {children}
    </TasksContext.Provider>
  );
};