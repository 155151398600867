import React from 'react';

enum ButtonStyle {
  Full = "full",
  Outline = "outline",
  Green = "green",
  Red = "red",
}

type ButtonType = "button" | "submit" | "reset";

interface BtnPrimaryProps {
  className?: string;
  children: React.ReactNode;
  onClick?: () => void;
  style?: string; 
  type?: ButtonType; 
  disabled?: boolean; 
}

const getButtonClasses = (style: string, disabled: boolean): string => {
  let classes = "flex items-center gap-2 transition duration-300 ease-in-out rounded-xl py-2 px-3.5 font-medium hover:bg-opacity-90";

  switch (style) {
    case ButtonStyle.Full:
      classes += " bg-primary text-white";
      break;
    case ButtonStyle.Outline:
      classes += " border border-primary text-primary";
      break;
    case ButtonStyle.Green:
      classes += " bg-green-700 text-white";
      break;
    case ButtonStyle.Red:
      classes += " bg-red-700 text-white";
      break;
    default:
      break;
  }

  if (disabled) {
    classes += " opacity-50 cursor-not-allowed";
  }

  return classes;
};

const BtnPrimary: React.FC<BtnPrimaryProps> = ({ className, children, onClick, style = ButtonStyle.Full, type = "button", disabled = false }) => {
  const buttonClasses = getButtonClasses(style, disabled);

  return (
    <button 
      type={type}
      onClick={disabled ? undefined : onClick} 
      disabled={disabled} 
      className={`${buttonClasses} ${className}`}>
      {children}
    </button>
  );
};

export default BtnPrimary;