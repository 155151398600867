import React from 'react';

interface InputFieldProps {
  label: string;
  value: string | number;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  placeholder: string;
  type?: string;
  className?: string;
  disabled?: boolean;
  step?: string; 
}

const InputField: React.FC<InputFieldProps> = ({ label, value, onChange, placeholder, type = 'text', className, disabled, step }) => (
  <div className={className}>
    <label className="block font-medium text-[#637381] mb-2.5 dark:text-white">{label}</label>
    <input
      autoComplete='off'
      type={type}
      placeholder={placeholder}
      value={value}
      onChange={onChange}
      disabled={disabled}
      step={step} 
      className={`
        w-full rounded-lg border-[1.5px] border-stroke bg-transparent p-3 pr-5 text-black outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:text-white dark:focus:border-primary 
        ${disabled ? 'disabled:cursor-default disabled:bg-whiter dark:disabled:bg-black dark:border-form-strokedark dark:bg-form-input  dark:focus:border-primary disabled:text-[#637381]' : ''}
      `}
    />
  </div> 
);

export default InputField;


 