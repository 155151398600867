import React from 'react';
import Breadcrumb from '../../../components/Breadcrumb/Breadcrumb';
import TableBotsAll from './TableBotsAll';
import { useBotsAll } from '../../../hooks/useBotsAll';
import Loader from '../../../common/Loader';

const BotsAll: React.FC = () => {
  const { botsAll, isLoading, error, getBotsAll, totalCount, handlePageChange, currentPage } = useBotsAll();
  // const { keys, setKeys, totalCount, fetchKeys, handlePageChange, currentPage, keysPerPage } = useApiKeys();

  if (isLoading) {
    return <Loader />;
  }

  return (
    <>
      <Breadcrumb pageName="Bots All" />
      
      <div className="grid grid-cols-12 gap-4 md:gap-6 2xl:gap-7.5 mt-4">
        <div className="col-span-12">
          <TableBotsAll 
            bots={botsAll} 
            updateBots={getBotsAll}
            totalCount={totalCount}
            onPageChange={handlePageChange}
            currentPage={currentPage} />
        </div>
      </div>
    </>
  );
};

export default BotsAll;