import React, { useState, useEffect } from 'react';
import { Toaster, toast } from 'sonner';
import { BsPlusCircleFill, BsXCircleFill } from "react-icons/bs";
import SimpleSelect from '../../../components/Forms/Select/SimpleSelect';
import BtnPrimary from '../../../components/Buttons/BtnPrimary';
import api from '../../../utils/api';
import { Db_Bots } from '../../../types/botsAll';
import { ApiResponse } from '../../../types/api';
import { KEY, GetApiKeysAll } from '../../../types/key';

interface AttachBotProps {
  bot: Db_Bots;
  onAttach: (result: boolean) => void;
  onClose: () => void;
}

const AttachDetachBot: React.FC<AttachBotProps> = ({ bot, onAttach, onClose }) => {
  const [selectedApiKey, setSelectedApiKey] = useState<string | null>(null);
  const [isKeysFetched, setIsKeysFetched] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [keys, setKeys] = useState<KEY[]>([]);

  const fetchKeys = async (page: number) => {
    try {
      const response = await api.get<ApiResponse<GetApiKeysAll>>(`/api/api_keys?page=${page + 1}&pageSize=10`);
      if (response.data.code === 200 && response.data.result) {
        setKeys(response.data.result.items);
      } else {
        setError('Failed to get all api keys');
      }
    } catch (error) {
      setError('An error occurred while fetching bots');
    }
  };

  useEffect(() => {
    // Выполняем запрос на получение ключей при открытии модального окна, если они еще не были получены
    if (!isKeysFetched) {
      fetchKeys(0);
      setIsKeysFetched(true);
    }

    // Устанавливаем выбранный ключ, если он привязан к боту
    if (bot?.api?.id) {
      setSelectedApiKey(bot.api.id);
    } else {
      setSelectedApiKey(null); // Устанавливаем значение по умолчанию, если ключ не выбран
    }
  }, [isKeysFetched, bot]);

  const handleApiKeyChange = (value: string) => {
    setSelectedApiKey(value);
  };

  const handleAttach = async () => {
    if (!selectedApiKey) {
      setError('Please select an API key');
      return;
    }

    try {
      const response = await api.post(`/bot-api/bots/attach-api-key/${bot._id}`, {
        api_key_id: selectedApiKey
      });

      if (response.data.code === 200 && response.data.result) {
        toast.success('API key attached successfully');
        onAttach(true);
        onClose();
      } else {
        setError('Failed to attach API key');
        onAttach(false);
      }
    } catch (error) {
      setError('An error occurred while attaching API key');
      onAttach(false);
    }
  };

  const transformKeysToOptions = (keys: KEY[]) => {
    return keys.map(key => ({
      value: key.id,
      label: key.name
    }));
  };

  return (
    <>
      <Toaster position="bottom-center" richColors />
      <div>
        <h3 className="mb-5 text-lg font-normal text-gray-500 dark:text-gray-400">
          Choose API key
        </h3>
        <SimpleSelect
          label='API Key'
          options={[{ value: '', label: 'Select option...' }, ...transformKeysToOptions(keys)]}
          value={selectedApiKey || ''}
          onChange={handleApiKeyChange}
          className="mb-4"
        />
        <div className='flex items-center justify-between gap-4 mt-auto'>
          <BtnPrimary
            className='w-full'
            onClick={handleAttach}
          >
            <BsPlusCircleFill /> Attach API Key
          </BtnPrimary>
          <BtnPrimary
            className='w-full'
            style="outline"
            onClick={onClose}
          >
            <BsXCircleFill /> Close
          </BtnPrimary>
        </div>
        {error && <p className="text-red-500 mt-4">{error}</p>}
      </div>
    </>
  );
};

export default AttachDetachBot;