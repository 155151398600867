import React, { useState } from 'react';
import Breadcrumb from '../../components/Breadcrumb/Breadcrumb';
import TableApiKeys from './TableApiKeys';
import ModalSideRight from '../../components/Modals/ModalSideRight';
import BtnPrimary from '../../components/Buttons/BtnPrimary';
import { BsPlusCircleFill } from "react-icons/bs";
import Key from './Key';
import { Toaster, toast } from 'sonner';
import { useApiKeys } from '../../hooks/useApiKeys';

const ApiKeys: React.FC = () => {
  const [isModalSideOpen, setIsModalSideOpen] = useState(false);
  const { keys, setKeys, totalCount, fetchKeys, handlePageChange, currentPage, keysPerPage } = useApiKeys();

  const toggleModalSide = () => {
    setIsModalSideOpen(!isModalSideOpen);
  };

  const handleSave = (result: boolean) => {
    if (result) {
      toast.success('Key added successfully');
      fetchKeys(currentPage);
    } else {
      toast.error('Failed to add key');
    }
  };

  return (
    <>
      <Toaster position="bottom-center" richColors />
      <Breadcrumb pageName="Api keys" />
      <BtnPrimary onClick={toggleModalSide} className='mb-4'><BsPlusCircleFill /> Add key</BtnPrimary>
      
      <ModalSideRight
        isOpen={isModalSideOpen}
        toggleModal={toggleModalSide}
        title="NEW KEY"
        content={ <Key toggleModal={toggleModalSide} action='add' onSave={handleSave} />}
      />
     
      <TableApiKeys 
        keys={keys} 
        totalCount={totalCount} 
        fetchKeys={fetchKeys} 
        onPageChange={handlePageChange} 
        currentPage={currentPage} 
        setKeys={setKeys} 
      />
    </>
  );
};

export default ApiKeys;