import { useState, useEffect, useCallback } from 'react';
import api from '../utils/api';
import { KEY, GetApiKeysAll, OrderHistory, GetOrderHistoryAll } from '../types/key';
import { ApiResponse } from '../types/api';
import { toast } from 'sonner';

export const useApiKeys = () => {
  const [keys, setKeys] = useState<KEY[]>([]);
  const [totalCount, setTotalCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const [isModalDeleteOpen, setIsModalDeleteOpen] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [isModalEditOpen, setIsModalEditOpen] = useState(false);
  const [isModalHistoryOrders, setIsModalHistoryOrders] = useState(false);
  const [selectedKey, setSelectedKey] = useState<KEY | null>(null);
  const [orderHistory, setOrderHistory] = useState<OrderHistory[]>([]); 
  const [orderHistoryPage, setOrderHistoryPage] = useState(0); 
  const [orderHistoryTotalCount, setOrderHistoryTotalCount] = useState(0); 

  const keysPerPage = 10;
  const orderHistoryPerPage = 10; 

  useEffect(() => {
    fetchKeys(currentPage);
  }, [currentPage]);

  const fetchKeys = useCallback(async (page: number) => {
    try {
      const response = await api.get<ApiResponse<GetApiKeysAll>>(`/api/api_keys?page=${page + 1}&pageSize=${keysPerPage}`);
      if (response.data && response.data.result) {
        setKeys(response.data.result.items);
        setTotalCount(response.data.result.totalCount);
      } else {
        setKeys([]);
        setTotalCount(0);
      }
    } catch (error) {
      console.error('Error fetching keys:', error);
    }
  }, [api, keysPerPage, setKeys, setTotalCount]);

  const handlePageChange = (selectedItem: { selected: number }) => {
    setCurrentPage(selectedItem.selected);
    fetchKeys(selectedItem.selected);
  };

  const toggleModalDelete = () => setIsModalDeleteOpen(!isModalDeleteOpen);
  const toggleModalEdit = () => setIsModalEditOpen(!isModalEditOpen);
  const toggleModalHistoryOrders = () => setIsModalHistoryOrders(!isModalHistoryOrders);

  const handleDeleteKey = (key: KEY) => {
    setSelectedKey(key);
    toggleModalDelete();
  };

  const deleteKey = async (id: string) => {
    try {
      const response = await api.delete(`/api/api_keys/${id}`);
      if (response.data.code === 200 && response.data.result) {
        console.log("Key deleted successfully");
        return true;
      } else {
        setError('Failed to delete key');
        return false;
      }
    } catch (error) {
      setError('An error occurred while deleting user');
      return false;
    }
  };

  const handleDeleteConfirm = async (result: boolean) => {
    if (result && selectedKey?.id) {
      try {
        const success = await deleteKey(selectedKey.id);
        if (success) {
          toast.success('Key deleted successfully');
          await fetchKeys(currentPage);
        } else {
          toast.error('Failed to delete key');
        }
      } catch (error) {
        setError('Failed to delete key');
      }
    }
    toggleModalDelete();
  };

  const handleSaveUpdate = (result: boolean) => {
    if (result) {
      fetchKeys(currentPage);
      toast.success('Key edited successfully');
    } else {
      toast.error('Failed to edit key');
    }
  };

  const handleEditKey = (key: KEY) => {
    setSelectedKey(key);
    toggleModalEdit();
  };

  const fetchOrderHistory = useCallback(async (subAccountId: string, page: number) => {
    try {
      const response = await api.get<ApiResponse<GetOrderHistoryAll>>(`/api/api_keys/orders/${subAccountId}?page=${page + 1}&pageSize=${orderHistoryPerPage}`);
      if (response.data && response.data.result) {
        setOrderHistory(response.data.result.items);
        setOrderHistoryTotalCount(response.data.result.totalCount);
      } else {
        setOrderHistory([]);
        setOrderHistoryTotalCount(0);
      }
    } catch (error) {
      console.error('Error fetching order history:', error);
    }
  }, [api, orderHistoryPerPage, setOrderHistory, setOrderHistoryTotalCount]);

  const handleHistoryOrdersKey = (key: KEY) => {
    setSelectedKey(key);
    toggleModalHistoryOrders();
    if (key.subAccountId) {
      fetchOrderHistory(key.subAccountId, orderHistoryPage);
    }
  };

  const handleOrderHistoryPageChange = (selectedItem: { selected: number }) => {
    setOrderHistoryPage(selectedItem.selected);
    if (selectedKey?.subAccountId) {
      fetchOrderHistory(selectedKey.subAccountId, selectedItem.selected);
    }
  };

  return {
    keys,
    setKeys,
    totalCount,
    currentPage,
    keysPerPage,
    fetchKeys,
    handlePageChange,
    isModalDeleteOpen,
    isModalEditOpen,
    selectedKey,
    isModalHistoryOrders,
    toggleModalHistoryOrders,
    handleHistoryOrdersKey,
    toggleModalDelete,
    toggleModalEdit,
    handleDeleteKey,
    handleDeleteConfirm,
    handleSaveUpdate,
    handleEditKey,
    orderHistory,
    orderHistoryPage,
    orderHistoryTotalCount,
    handleOrderHistoryPageChange,
  };
};