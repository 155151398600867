import axios from 'axios';
import { checkAuth } from './checkAuth';

// Функция api для работы с защищенными маршрутами, используя токены из localStorage.
const api = axios.create();

api.interceptors.request.use(
  async (config) => {
    const accessToken = localStorage.getItem('accessToken');
    if (accessToken) {
      config.headers.Authorization = `Bearer ${accessToken}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

api.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    const originalRequest = error.config;
    if (error.response && error.response.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;

      // Проверка авторизации и обновление токенов
      const isAuthenticated = await checkAuth();
      if (isAuthenticated) {
        const accessToken = localStorage.getItem('accessToken');
        originalRequest.headers.Authorization = `Bearer ${accessToken}`;
        return api(originalRequest);
      } else {
        // Если обновление токенов не удалось, перенаправляем пользователя на страницу логина
        localStorage.removeItem('user');
        localStorage.removeItem('accessToken');
        localStorage.removeItem('refreshToken');
        window.location.href = '/';
      }
    }
    return Promise.reject(error);
  }
);

export default api;