import React, { useState, useEffect } from 'react';
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import Loader from './common/Loader';
import PageTitle from './components/PageTitle';
import SignIn from './pages/Authentication/SignIn';
import Dashboard from './pages/Dashboard/Dashboard';
import ErrorPage from './pages/Error/Error';
import ApiKeys from './pages/ApiKeys/ApiKeys';
import DefaultLayout from './layout/DefaultLayout';
import SignInLayout from './layout/SignInLayout';
import Users from './pages/Users/Users';
import Profile from './pages/Profile/Profile';
import { UserProvider } from './context/UserContext';
import { TasksProvider } from './context/TasksContext';
import { checkAuth } from './utils/checkAuth';
import ManualTrading from './pages/ManualTrading/ManualTrading';
import BotSettings from './pages/Bots/All/BotSettings';
import BotsAll from './pages/Bots/All/BotsAll';
import Benchmark from './pages/Benchmark/Benchmark';
import ReportPage from './pages/Report/Report';

function App() {
  const [loading, setLoading] = useState<boolean>(true);
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const user = JSON.parse(localStorage.getItem('user') || '{}');

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  useEffect(() => {
    const checkUserAuth = async () => {
      const isAuthenticated = await checkAuth();
      if (!isAuthenticated) {
        localStorage.removeItem('user');
        localStorage.removeItem('accessToken');
        localStorage.removeItem('refreshToken');
        if (pathname !== '/') {
          navigate('/');
        }
      } else {
        if (pathname === '/') {
          navigate('/users');
        }
      }
      setLoading(false);
    };

    checkUserAuth();
  }, [pathname, navigate]);

  return loading ? (
    <Loader />
  ) : (
    <UserProvider>
      <TasksProvider>
        <Routes>
          <Route
            path="/"
            element={
              <SignInLayout>
                <PageTitle title="Sign in" />
                <SignIn />
              </SignInLayout>
            }
          />
          <Route
            path="/dashboard"
            element={
              <DefaultLayout>
                <PageTitle title="Dashboard" />
                <Dashboard />
              </DefaultLayout>
            }
          />
          {/* <Route
            path="/benchmark"
            element={
              <DefaultLayout>
                <PageTitle title="Benchmark" />
                <Benchmark />
              </DefaultLayout>
            }
          /> */}
          <Route
            path="/profile"
            element={
              <DefaultLayout>
                <PageTitle title="Profile" />
                <Profile />
              </DefaultLayout>
            }
          />
          <Route
            path="/users"
            element={
              <DefaultLayout>
                <PageTitle title="Users" />
                <Users />
              </DefaultLayout>
            }
          />
          <Route
            path="/bots-all"
            element={
              <DefaultLayout>
                <PageTitle title="Bots All" />
                <BotsAll />
              </DefaultLayout>
            }
          />
          {/* <Route
            path="/bots-test"
            element={
              <DefaultLayout>
                <PageTitle title="Bots Test" />
                <Dashboard />
              </DefaultLayout>
            }
          /> */}
          {/* <Route
            path="/bots-investors"
            element={
              <DefaultLayout>
                <PageTitle title="Bots Investors" />
                <Dashboard />
              </DefaultLayout>
            }
          /> */}
          {/* <Route
            path="/bots-btc"
            element={
              <DefaultLayout>
                <PageTitle title="Bots BTC" />
                <Dashboard />
              </DefaultLayout>
            }
          /> */}
          {/* <Route
            path="/settings"
            element={
              <DefaultLayout>
                <PageTitle title="Settings" />
                <Settings />
              </DefaultLayout>
            }
          /> */}
          <Route
            path="/api-keys"
            element={
              <DefaultLayout>
                <PageTitle title="Api keys" />
                <ApiKeys />
              </DefaultLayout>
            }
          />
          <Route
            path="/manual-trading/:keyId"
            element={
              <DefaultLayout>
                <PageTitle title="Manual trading" />
                <ManualTrading />
              </DefaultLayout>
            }
          />
          <Route
            path="/bot/:botUuid"
            element={
              <DefaultLayout>
                <PageTitle title="Bot settings" />
                <BotSettings />
              </DefaultLayout>
            }
          />
          {/* <Route
            path="/api-keys-test"
            element={
              <DefaultLayout>
                <PageTitle title="Api keys" />
                <ApiKeysTest />
              </DefaultLayout>
            }
          /> */}
          {/* <Route
            path="/orders"
            element={
              <DefaultLayout>
                <PageTitle title="Orders" />
                <Orders />
              </DefaultLayout>
            }
          /> */}
          <Route
            path="/error"
            element={
              <DefaultLayout>
                <PageTitle title="Error" />
                <ErrorPage />
              </DefaultLayout>
            }
          />
          {/* <Route
            path="/report/:idTask"
            element={
              <DefaultLayout>
                <PageTitle title="Report" />
                <ReportPage />
              </DefaultLayout>
            }
          /> */}
          <Route
            path="*"
            element={
              <DefaultLayout>
                <PageTitle title="Error" />
                <ErrorPage />
              </DefaultLayout>
            }
          />
        </Routes>
      </TasksProvider>
    </UserProvider>
  );
}

export default App;