import React, { useState, useEffect } from 'react';
import { BsEnvelopeFill } from 'react-icons/bs';
import { validateEmail } from '../../../utils/validations';

interface InputEmailProps {
  name?: string,
  value: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  validate?: (value: string) => boolean;
  className?: string,
  disabled?: boolean;
}

const InputEmail: React.FC<InputEmailProps> = ({ name, value, onChange, validate = validateEmail, className, disabled }) => {
  const [isValid, setIsValid] = useState<boolean | null>(null);
  const [errorMessage, setErrorMessage] = useState('');

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.value;
    onChange(e);

    if (newValue.length > 0) {
      const valid = validate(newValue);
      setIsValid(valid);
      if (!valid) {
        setErrorMessage('Invalid email format');
      } else {
        setErrorMessage('');
      }
    } else {
      setIsValid(null);
      setErrorMessage('');
    }
  };

  useEffect(() => {
    if (isValid) {
      setErrorMessage('');
    }
  }, [isValid]);

  return (
    <div className={`${className}`}>
      <label className="mb-2.5 block font-medium text-[#637381] dark:text-white">
        Email
      </label>
      <div className="relative">
        <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
          <BsEnvelopeFill className="text-gray-500 text-xl" />
        </div>
        <input
          name={name}
          type="email"
          placeholder="Enter your email"
          disabled={disabled}
          autoComplete='off'
          className={`
            w-full rounded-lg border-[1.5px] border-form-strokedark focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input py-3 pl-12 pr-12 text-white outline-none focus-visible:shadow-none border-form-stroke bg-form-input transition-all
            ${isValid === null ? '' : isValid ? 'border-green-500 focus:border-green-500' : 'border-red-500 focus:border-red-500'}
          `}
          value={value}
          onChange={handleInputChange}
        />
      </div>
      {errorMessage && <p className="text-red-500 mt-2">{errorMessage}</p>}
    </div>
  );
};

export default InputEmail;